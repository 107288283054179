import PropTypes from "prop-types";
import styles from "./StyleCount.css";

const StyleCount = ({ itemCount }, { formatTranslation, formatNumber }) => (
  <p className={styles.styleCount}>
    {formatTranslation("plp_web_style_count_noun", {
      smart_count: itemCount,
      formattedCount: formatNumber(itemCount)
    })}
  </p>
);

StyleCount.propTypes = {
  itemCount: PropTypes.number.isRequired
};

StyleCount.contextTypes = {
  formatTranslation: PropTypes.func.isRequired,
  formatNumber: PropTypes.func.isRequired
};

export default StyleCount;
