import { useEffect, useRef } from "react";
import refreshSlot from "./refreshSlot";

const useRefreshIfPriorSiblingsChanged = ({
  slot,
  priorSiblingsFingerprint
}) => {
  const fingerprintRef = useRef(priorSiblingsFingerprint);
  useEffect(() => {
    if (slot && fingerprintRef.current !== priorSiblingsFingerprint) {
      refreshSlot({ slot });
    }
    fingerprintRef.current = priorSiblingsFingerprint;
  }, [slot, priorSiblingsFingerprint]);
};

export default useRefreshIfPriorSiblingsChanged;
