import { useEffect, useRef } from "react";
import refreshSlot from "./refreshSlot";

const useSlotSizeRefresh = ({ slot, validSlotSizes }) => {
  const primitiveSlotSizes = validSlotSizes.join(";");
  const slotSizeRef = useRef(primitiveSlotSizes);

  useEffect(() => {
    const adHasFetched = !!slot?.getResponseInformation();
    if (adHasFetched && primitiveSlotSizes !== slotSizeRef.current) {
      refreshSlot({ slot });
    }
    slotSizeRef.current = primitiveSlotSizes;
  }, [slot, primitiveSlotSizes]);
};

export default useSlotSizeRefresh;
