import VideoWithFallback from "./VideoWithFallback";
import ProductHeroContainer from "../../ProductHeroContainer";

const EagerVideo = props => (
  <ProductHeroContainer>
    <VideoWithFallback {...props} />
  </ProductHeroContainer>
);

export default EagerVideo;
