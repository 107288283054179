import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./index.css";

const HorizontalScrollList = ({
  children,
  className,
  scrollSnapping = false
}) => (
  <div className={classnames(styles.container, className)}>
    <ul
      className={classnames(styles.list, {
        [styles.scrollSnapping]: scrollSnapping
      })}
    >
      {children}
    </ul>
  </div>
);

HorizontalScrollList.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  scrollSnapping: PropTypes.bool
};

export default HorizontalScrollList;
