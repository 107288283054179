import { useDispatch } from "react-redux";
import SinglePageAppLink from "./SinglePageAppLink";
import { setIsNewPage } from "../../state/modules/page/actions";

const ConnectedSinglePageAppLink = props => {
  const dispatch = useDispatch();

  return (
    <SinglePageAppLink
      {...props}
      setIsNewPage={() => dispatch(setIsNewPage(true))}
    />
  );
};

export default ConnectedSinglePageAppLink;
