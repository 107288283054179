import PropTypes from "prop-types";
import { getFacetSelectedValues } from "../../../state/modules/search";
import styles from "./RefinementItem.css";

const RefinementItem = ({ facet }) => (
  <span>
    {facet.name}
    <span className={styles.facetValues}>{getFacetSelectedValues(facet)}</span>
  </span>
);

RefinementItem.propTypes = {
  facet: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
};

export default RefinementItem;
