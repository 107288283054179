import PropTypes from "prop-types";
import classnames from "classnames";
import { withTranslation } from "../../../translation";
import FromLabel from "../FromLabel";
import styles from "./index.css";

const OriginalPrice = ({
  price,
  isOutlet,
  isMarkedDown,
  formatTranslation,
  shouldDisplayFrom
}) => (
  <span
    className={classnames(styles.originalPrice, {
      [styles.markedDown]: isMarkedDown
    })}
  >
    {shouldDisplayFrom && <FromLabel />}
    {isOutlet && <span>{formatTranslation("generic_price_rrp")}</span>}
    <span className={classnames(styles.price)}>{price}</span>
  </span>
);

OriginalPrice.propTypes = {
  price: PropTypes.string.isRequired,
  isMarkedDown: PropTypes.bool.isRequired,
  isOutlet: PropTypes.bool.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  shouldDisplayFrom: PropTypes.bool.isRequired
};

export default withTranslation(OriginalPrice);
