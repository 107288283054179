import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import debounce from "../../helpers/debounce";
import {
  hideFilterOverlay,
  updateViewportCategory,
  updateClientWidth
} from "../../state/modules/layout/actions";
import {
  SMALL_VIEWPORT,
  MEDIUM_VIEWPORT,
  LARGE_VIEWPORT
} from "../../state/modules/layout/constants";

const DEBOUNCE_TIMEOUT = 200;
export const SMALL_VIEWPORT_MAX_SIZE = 768;
export const MEDIUM_VIEWPORT_MAX_SIZE = 1024;

export class LayoutManager extends Component {
  static getViewportSize() {
    const size = window.innerWidth;
    if (size < SMALL_VIEWPORT_MAX_SIZE) {
      return SMALL_VIEWPORT;
    } else if (size < MEDIUM_VIEWPORT_MAX_SIZE) {
      return MEDIUM_VIEWPORT;
    }

    return LARGE_VIEWPORT;
  }

  constructor(props) {
    super(props);
    this.timer = null;
    this.onResize = debounce(() => {
      this.updateViewportCategory();
      this.updateClientWidth(document.body.clientWidth);
    }, DEBOUNCE_TIMEOUT);
    this.state = { clientWidth: null };
  }

  componentDidMount() {
    this.onResize();
    /* istanbul ignore next */
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    /* istanbul ignore next */
    window.removeEventListener("resize", this.onResize);
  }

  updateViewportCategory = () => {
    const viewportSize = LayoutManager.getViewportSize();
    if (viewportSize === this.viewportSize) {
      return;
    }
    if (viewportSize === LARGE_VIEWPORT) {
      this.props.hideFilterOverlay();
    }
    this.props.updateViewportCategory(viewportSize);
    this.viewportSize = viewportSize;
  };

  updateClientWidth = newWidth => {
    const { clientWidth } = this.state;
    if (newWidth !== clientWidth) {
      this.props.updateClientWidth(newWidth);
      this.setState({ clientWidth: newWidth });
    }
  };

  render() {
    return null;
  }
}

LayoutManager.propTypes = {
  hideFilterOverlay: PropTypes.func.isRequired,
  updateViewportCategory: PropTypes.func.isRequired,
  updateClientWidth: PropTypes.func.isRequired
};

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideFilterOverlay,
      updateViewportCategory,
      updateClientWidth
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(LayoutManager);
