import PropTypes from "prop-types";
import { withTranslation } from "../../translation";
import NotFoundMessage from "../NotFoundMessage";
import ShopWomenAndMen from "../../ShopWomenAndMen";

const NoResults = ({ searchTerm, formatTranslation, formatLink }) => (
  <div>
    <NotFoundMessage
      errorMessage={formatTranslation("plp_web_search_error_message", {
        searchTerm: searchTerm ?? ""
      })}
      suggestionMessage={formatTranslation("plp_web_search_suggestion_message")}
    />
    <ShopWomenAndMen
      shopWomenLink={formatLink("")}
      shopWomenMessage={formatTranslation("plp_web_search_shop_women")}
      shopMenLink={formatLink("")}
      shopMenMessage={formatTranslation("plp_web_search_shop_men")}
    />
  </div>
);

NoResults.propTypes = {
  searchTerm: PropTypes.string,
  formatTranslation: PropTypes.func.isRequired,
  formatLink: PropTypes.func.isRequired
};

NoResults.displayName = "NoResults";

export default withTranslation(NoResults);
