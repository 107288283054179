import PropTypes from "prop-types";
import { useContext } from "react";
import classNames from "classnames";
import ReducedPrice from "../../../ReducedPrice";
import OriginalPrice from "../OriginalPrice";
import styles from "./index.css";
import getPriceInfo from "../../../getPriceInfo";
import { withTranslation } from "../../../../../translation";
import ProductDeal from "../ProductDeal";
import TileContext from "../../../../../TileContext";
import { TILE_SIZES } from "../../../../../../helpers/constants";

const { TILE_SIZE_TWO_BY_ONE, TILE_SIZE_TWO_BY_TWO } = TILE_SIZES;

const displayOriginalPrice = (euOmnibusEnabled, isMarkedDown) =>
  euOmnibusEnabled ? !isMarkedDown : true;

const shouldDisplayFrom = (hasMultiplePrices, reducedPrice) =>
  hasMultiplePrices && reducedPrice === null;

const ProductPrice = ({
  price,
  isSale,
  isOutlet,
  reducedPrice,
  hasMultiplePrices,
  labelledById,
  formatTranslation,
  euOmnibusEnabled,
  deal,
  dealAria
}) => {
  const isMarkedDown = isSale || isOutlet;
  const { tileSize } = useContext(TileContext);

  return (
    <p
      id={labelledById}
      aria-hidden={true}
      aria-label={getPriceInfo({
        price,
        reducedPrice,
        isOutlet,
        hasMultiplePrices,
        formatTranslation,
        euOmnibusEnabled
      })}
      className={classNames(styles.container, {
        [styles.twoByOne]: tileSize === TILE_SIZE_TWO_BY_ONE,
        [styles.twoByTwo]: tileSize === TILE_SIZE_TWO_BY_TWO
      })}
    >
      {displayOriginalPrice(euOmnibusEnabled, isMarkedDown) && (
        <OriginalPrice
          price={price}
          isMarkedDown={isMarkedDown}
          isOutlet={isOutlet}
          shouldDisplayFrom={shouldDisplayFrom(hasMultiplePrices, reducedPrice)}
        />
      )}
      {reducedPrice && (
        <ReducedPrice
          isMarkedDown={isMarkedDown}
          reducedPrice={reducedPrice}
          hasMultiplePrices={hasMultiplePrices}
        />
      )}
      {deal && (
        <ProductDeal
          deal={deal}
          dealAria={dealAria}
          labelledById={labelledById}
        />
      )}
    </p>
  );
};

ProductPrice.propTypes = {
  price: PropTypes.string.isRequired,
  isSale: PropTypes.bool,
  isOutlet: PropTypes.bool,
  reducedPrice: PropTypes.string,
  deal: PropTypes.string,
  dealAria: PropTypes.string.isRequired,
  hasMultiplePrices: PropTypes.bool,
  labelledById: PropTypes.string.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  euOmnibusEnabled: PropTypes.bool.isRequired
};

ProductPrice.defaultProps = {
  hasMultiplePrices: false,
  isOutlet: false,
  reducedPrice: null,
  isSale: false
};

export default withTranslation(ProductPrice);
