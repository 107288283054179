import ErrorBoundary from "../../../containers/ErrorBoundary";
import CategoryPreviewCarouselSkeleton from "./CategoryPreviewCarouselSkeleton";
import loadable from "@loadable/component";

const CategoryPreviewCarouselLoader = loadable(
  () =>
    import(
      /* webpackPreload: true, webpackChunkName: "CATEGORY_PREVIEW_CAROUSEL" */ "./CategoryPreviewCarouselContainer"
    ),
  { fallback: <CategoryPreviewCarouselSkeleton /> }
);

const CategoryPreviewCarousel = props => (
  <ErrorBoundary ErrorComponent={() => null}>
    <CategoryPreviewCarouselLoader {...props} />
  </ErrorBoundary>
);

export default CategoryPreviewCarousel;
