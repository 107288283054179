import ErrorBoundary from "../../../containers/ErrorBoundary";
import CategoryPreviewGridSkeleton from "./CategoryPreviewGridSkeleton";
import loadable from "@loadable/component";

const CategoryPreviewGridLoader = loadable(
  () =>
    import(
      /* webpackPreload: true, webpackChunkName: "CATEGORY_PREVIEW_GRID" */ "./CategoryPreviewGridContainer"
    ),
  { fallback: <CategoryPreviewGridSkeleton /> }
);

const CategoryPreviewGrid = props => (
  <ErrorBoundary ErrorComponent={() => null}>
    <CategoryPreviewGridLoader {...props} />
  </ErrorBoundary>
);

export default CategoryPreviewGrid;
