import cookie from "js-cookie";

const PROMO_ONBOARD_DISMISSED_COOKIE_KEY = "asos-pd2407";

export const getPromoOnboardDismissedFromCookie = ({ cookies }) =>
  cookies[PROMO_ONBOARD_DISMISSED_COOKIE_KEY] || false;

export const setPromoOnboardDismissedFromCookie = promoOnboardDismissed => {
  if (CLIENT) {
    cookie.set(PROMO_ONBOARD_DISMISSED_COOKIE_KEY, promoOnboardDismissed, {
      expires: 7
    });
  }
};
