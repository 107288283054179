import PropTypes from "prop-types";
import VideoWithFallback from "../../../VideoWithFallback";
import { useInView } from "react-intersection-observer";
import LazyProductHeroContainer from "./LazyProductHeroContainer";

const LazyVideo = ({ isMediumViewportOrGreater, ...rest }) => {
  const { ref, inView } = useInView({
    rootMargin: isMediumViewportOrGreater ? "610px" : "710px",
    triggerOnce: true,
    skip: isMediumViewportOrGreater === null
  });

  return (
    <LazyProductHeroContainer
      ref={ref}
      doLoad={inView}
      render={lazyProps => (
        <VideoWithFallback
          {...{
            isMediumViewportOrGreater,
            ...lazyProps,
            ...rest
          }}
        />
      )}
    />
  );
};

LazyVideo.propTypes = {
  isMediumViewportOrGreater: PropTypes.bool
};

export default LazyVideo;
