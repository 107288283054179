import PropTypes from "prop-types";
import ProductHeroContainer from "../../../../../ProductHeroContainer";

const EagerImage = ({ ImageComponent, ...rest }) => (
  <ProductHeroContainer>
    <ImageComponent {...rest} />
  </ProductHeroContainer>
);

EagerImage.propTypes = {
  ImageComponent: PropTypes.elementType.isRequired
};

export default EagerImage;
