import { DELIMITER, PREFIX } from "./ariaConstants";

const orderedKeys = [
  "title",
  "price",
  "deal",
  "sponsored",
  "productBadge",
  "sellingFast",
  "promoBadge"
];

const getAriaLabelledBy = ({ productId }) => {
  const map = orderedKeys.reduce(
    (map, key, index) => ({
      ...map,
      [key]: `${PREFIX}-${productId}-${index}`
    }),
    {}
  );
  const labelledBy = Object.values(map).join(` ${DELIMITER} `);

  return {
    ...map,
    labelledBy
  };
};

export default getAriaLabelledBy;
