import { useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./ProductVideo.css";
import useVideoSrcDownloadOnMount from "./useVideoSrcDownloadOnMount";
import usePlayWhenInView from "./usePlayWhenInView";
import { getFallbackImage } from "../ProductImage";
import classnames from "classnames";

const ProductVideo = ({
  image,
  videoUrl,
  onError,
  isMediumViewportOrGreater,
  ImageComponent,
  ...rest
}) => {
  const ref = useRef(null);
  const [isVideoFadedIn, setIsVideoFadedIn] = useState(false);

  const videoSrc = useVideoSrcDownloadOnMount({
    videoUrl,
    useHighBitrate: isMediumViewportOrGreater,
    onError
  });

  const hasFirstPlayed = usePlayWhenInView({
    isMediumViewportOrGreater,
    videoElement: ref.current,
    videoSrc,
    onError
  });

  return (
    <>
      {!isVideoFadedIn && <ImageComponent {...{ image, ...rest }} />}
      <video
        className={classnames(styles.productVideo, {
          [styles.hasFirstPlayed]: hasFirstPlayed
        })}
        ref={ref}
        src={videoSrc}
        poster={getFallbackImage(image)}
        onTransitionEnd={() => setIsVideoFadedIn(true)}
        onError={onError}
        loop
        muted
        playsInline
      ></video>
    </>
  );
};

ProductVideo.propTypes = {
  image: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  isMediumViewportOrGreater: PropTypes.bool,
  ImageComponent: PropTypes.elementType.isRequired
};

export default ProductVideo;
