const stringify = object =>
  JSON.stringify(object, (_, value) => {
    switch (value.constructor) {
      case Object:
        return Object.entries(value)
          .sort()
          .reduce((output, [key, value]) => ({ ...output, [key]: value }), {});
      case Array:
        return value.sort();
      default:
        return value;
    }
  });

// shame they don't document the in-built version of this...
const getTargetingMap = ({ getTargetingKeys, getTargeting }) =>
  getTargetingKeys().reduce(
    (map, key) => ({ ...map, [key]: getTargeting(key) }),
    {}
  );

const hasTargetingChanged = ({ slot, targeting }) =>
  stringify(getTargetingMap(slot)) !== stringify(targeting);

export default hasTargetingChanged;
