import SmallScreenHeader from "../../components/SmallScreenHeader";
import { useDispatch, useSelector } from "react-redux";
import { showFilterOverlay } from "../../state/modules/layout/actions";
import { hasSelectedFacets } from "../../state/modules/search/selectors";
import { withTranslation } from "../../components/translation/";

const ConnectedSmallScreenHeader = props => {
  const dispatch = useDispatch();

  return (
    <SmallScreenHeader
      hasSelectedFacets={useSelector(hasSelectedFacets)}
      showFilterOverlay={() => dispatch(showFilterOverlay())}
      {...props}
    />
  );
};

export default withTranslation(ConnectedSmallScreenHeader);
