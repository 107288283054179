import PropTypes from "prop-types";
import styles from "./ColourSwatch.css";
import classnames from "classnames";

const ColourSwatch = ({ backgroundColour, classNames }) => (
  <div
    className={classnames(styles.swatch, classNames)}
    style={{ backgroundColor: backgroundColour }}
  ></div>
);

ColourSwatch.propTypes = {
  backgroundColour: PropTypes.string,
  classNames: PropTypes.string
};

export default ColourSwatch;
