import PropTypes from "prop-types";
import styles from "./index.css";

const NewPill = ({ formatTranslation }) => (
  <div className={styles.newPill}>
    <span>{formatTranslation("plp_web_refinements_list_feature_new_tag")}</span>
  </div>
);

NewPill.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default NewPill;
