import ProductTile from "../../../ProductTile.js";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import { sendPlacementOnViewBeacon } from "../../../../../state/modules/search/actions.js";
import { anchorProductToUrl } from "../../../../../state/modules/search/productTile/actions.js";
import { analyticsProductClick } from "../../../../../state/modules/analytics/actions/index.js";
import { getViewportCategory } from "../../../../../state/modules/layout/selectors.js";
import getBadges from "../../../Badges/__variants__/plp-promo-label/shown/index.js";
import TileContext from "../../../../TileContext/index.js";

const ConnectedProductTile = props => {
  const dispatch = useDispatch();
  const viewportCategory = useSelector(getViewportCategory);
  const { tileSize } = useContext(TileContext);

  return (
    <ProductTile
      {...{
        ...props,
        ...getBadges({
          viewportCategory,
          tileSize
        }),
        sendPlacementOnViewBeacon: () => dispatch(sendPlacementOnViewBeacon()),
        analyticsProductClick: args => dispatch(analyticsProductClick(args)),
        anchorProductToUrl: args => dispatch(anchorProductToUrl(args))
      }}
    />
  );
};

export default ConnectedProductTile;
