import PropTypes from "prop-types";
import styles from "./ImageLink.css";
import commonConfig from "../../../config/common";

const { assetsBasePath } = commonConfig;

const ImageLink = ({ image, children, url, alt = "" }) => {
  const imageSrcPrefix = SERVER ? assetsBasePath : "";

  return (
    <a href={url} className={styles.imageLink}>
      <span className={styles.text}>{children}</span>
      <img
        className={styles.image}
        src={`${imageSrcPrefix}${image}`}
        alt={alt}
      />
    </a>
  );
};

ImageLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
  basePath: PropTypes.string
};

ImageLink.displayName = "ImageLink";

export default ImageLink;
