import PropTypes from "prop-types";
import LazyProductHeroContainer from "./LazyProductHeroContainer";
import { useInView } from "react-intersection-observer";

const LazyImage = ({ isMediumViewportOrGreater, ImageComponent, ...rest }) => {
  const { ref, inView } = useInView({
    rootMargin: isMediumViewportOrGreater ? "510px" : "410px",
    triggerOnce: true,
    skip: isMediumViewportOrGreater === null
  });

  return (
    <LazyProductHeroContainer
      ref={ref}
      doLoad={inView}
      render={lazyProps => (
        <ImageComponent
          {...{
            ...rest,
            ...lazyProps
          }}
        />
      )}
    />
  );
};

LazyImage.propTypes = {
  isMediumViewportOrGreater: PropTypes.bool,
  ImageComponent: PropTypes.elementType.isRequired
};

export default LazyImage;
