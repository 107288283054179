import { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ProductHeroContainer from "../../../../../../ProductHeroContainer";
import styles from "./index.css";

const LazyProductHeroContainer = forwardRef(
  ({ doLoad, render, className }, ref) => {
    const [loaded, setLoaded] = useState(false);
    const [fadeComplete, setFadeComplete] = useState(false);

    return (
      <ProductHeroContainer
        className={classnames(styles.lazyProductHeroContainer, className, {
          [styles.fadeComplete]: fadeComplete
        })}
        ref={ref}
      >
        {doLoad
          ? render({
              className: classnames(styles.lazy, {
                [styles.show]: loaded
              }),
              onLoad: () => setLoaded(true),
              onTransitionEnd: () => setFadeComplete(true)
            })
          : null}
      </ProductHeroContainer>
    );
  }
);

LazyProductHeroContainer.displayName = "LazyProductHeroContainer";

LazyProductHeroContainer.propTypes = {
  render: PropTypes.func.isRequired,
  doLoad: PropTypes.bool.isRequired,
  className: PropTypes.string
};

export default LazyProductHeroContainer;
