import PropTypes from "prop-types";
import styles from "./index.css";
import classnames from "classnames";
import { withTranslation } from "../../../translation";

const FromLabel = ({ formatTranslation, isMarkedDown }) => (
  <span
    className={classnames(styles.fromLabel, {
      [styles.isMarkedDown]: isMarkedDown
    })}
  >
    {formatTranslation("plp_web_product_tile_price_from")}
  </span>
);

export default withTranslation(FromLabel);

FromLabel.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  isMarkedDown: PropTypes.bool
};
