import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { APP } from "@asosteam/asos-web-adverts-sdk";
import CriteoAds from "./CriteoAds";
import {
  getIsSearchPage,
  getIsServerRendered
} from "../../state/modules/page/selectors";
import {
  getSearchTerm,
  getAllAppliedRefinements
} from "../../state/modules/search/selectors";
import { getCategoryId } from "../../state/modules/category/selectors";
import getSponsoredProductIds from "../../state/modules/search/getSponsoredProductIds";
import getPageNumber from "../../state/modules/search/getPageNumber";
import { getSort } from "../../state/modules/search";
import getFiltersString from "../../client/adverts/getFiltersString";
import getSponsoredProducts from "../../state/modules/search/getSponsoredProducts";
import getProductsForPage from "../../state/modules/search/getProductsForPage";
import { shouldRequestSponsoredProducts } from "./shouldRequestSponsoredProducts";

const ConnectedCriteoAdvert = ({ className }) => {
  const app = useSelector(getIsSearchPage) ? APP.SEARCH : APP.CATEGORY;
  const pageNumber = useSelector(getPageNumber);
  const isServerRendered = useSelector(getIsServerRendered);

  const totalProducts = useSelector(state => getProductsForPage(state, 1))
    .length;
  const totalSponsoredProducts = useSelector(getSponsoredProducts).length;
  const listSize = totalProducts - totalSponsoredProducts;

  const allAppliedRefinements = useSelector(getAllAppliedRefinements);
  const serialisedFilters = getFiltersString(allAppliedRefinements);

  const parameters = {
    "page-number": pageNumber,
    "list-size": listSize,
    filters: serialisedFilters
  };

  const sponsoredProductIds = useSelector(getSponsoredProductIds);
  const isSorted = useSelector(getSort);
  const requestSponsoredProducts = shouldRequestSponsoredProducts({
    sponsoredProductIds,
    isSorted,
    isServerRendered
  });

  if (requestSponsoredProducts) {
    parameters["item-whitelist"] = sponsoredProductIds;
  }

  const keywords = useSelector(getSearchTerm);
  const category = useSelector(getCategoryId);

  if (app === APP.SEARCH) {
    parameters.keywords = keywords;
  } else {
    parameters.category = category;
  }

  return (
    <CriteoAds
      app={app}
      requestSponsoredProducts={requestSponsoredProducts}
      parameters={parameters}
      className={className}
    />
  );
};

ConnectedCriteoAdvert.propTypes = {
  className: PropTypes.string
};

export default ConnectedCriteoAdvert;
