import PropTypes from "prop-types";
import styles from "./index.css";
import classnames from "classnames";

const ProductDeal = ({ deal, dealAria, className, labelledById }) => (
  <div
    id={labelledById}
    aria-hidden={true}
    aria-label={dealAria}
    className={classnames(styles.productDeal, className)}
  >
    {deal}
  </div>
);

ProductDeal.propTypes = {
  deal: PropTypes.string.isRequired,
  dealAria: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelledById: PropTypes.string.isRequired
};

export default ProductDeal;
