import { MEDIUM_VIEWPORT } from "../../../state/modules/layout/constants";
const featuredProductsAnalyticsString = `ctaref=featured+product&featureref1=featured+product`;
import { TILE_SIZES } from "../../../helpers/constants";

const { TILE_SIZE_TWO_BY_ONE, TILE_SIZE_TWO_BY_TWO } = TILE_SIZES;

export const shouldTrackProductClick = ({
  carouselCatId,
  advertId,
  sendTreatmentAnalytics
}) => carouselCatId || advertId || sendTreatmentAnalytics;

export const shouldSendTreatmentAnalytics = ({ tileSize, viewportCategory }) =>
  (tileSize === TILE_SIZE_TWO_BY_ONE || tileSize === TILE_SIZE_TWO_BY_TWO) &&
  viewportCategory !== MEDIUM_VIEWPORT;

export const updateHrefForAnalytics = ({ href, sendTreatmentAnalytics }) => {
  const symbol = href.includes("?") ? "&" : "?";

  if (!sendTreatmentAnalytics) {
    return href;
  }

  let start, hash;
  if (href.includes("#")) {
    start = href.slice(0, href.indexOf("#"));
    hash = href.slice(href.indexOf("#"));

    return `${start}${symbol}${featuredProductsAnalyticsString}&${hash}`;
  }

  return `${href}${symbol}${featuredProductsAnalyticsString}`;
};
