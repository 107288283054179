import { withTogglePointFactory } from "@asosteam/asos-web-toggle-point-cuts";
import {
  decisionsContext,
  useActivation
} from "@asosteam/asos-web-toggle-point-features";
import sdkInstanceProvider from "../../client/sdkProvider";
import logger from "../../lib/logger";
import { LOG_LEVEL_ERROR } from "../../lib/logger/constants";

const withTogglePoint = withTogglePointFactory({
  sdkInstanceProvider,
  decisionsContext,
  useActivation,
  logError: logger[LOG_LEVEL_ERROR]
});

export default withTogglePoint;
