/* istanbul ignore file */
/* This is tested by our intergration tests as it's not possible to test this in React Testing Libary*/
import {
  CATEGORY_BANNER_ID,
  CHROME_BREADCRUMB_ID,
  SEARCH_TERM_ID,
  MISC_BAR_HEIGHT,
  VIDEO_HEADER
} from "../constants";

const BOTTOM_SPACE = 32;

export const getVerticalDisplacement = element =>
  element
    ? Math.max(
        element.getBoundingClientRect().bottom + BOTTOM_SPACE,
        BOTTOM_SPACE
      )
    : 0;

// if there is a mismatch in the rendering between server
// and client these elements will be replaced by new ones
// making the getPinStart to return 0...
const pinStartElements = (CLIENT
  ? [
      document.getElementById(CHROME_BREADCRUMB_ID),
      document.getElementById(CATEGORY_BANNER_ID),
      document.getElementById(SEARCH_TERM_ID),
      document.getElementById(VIDEO_HEADER)
    ]
  : []
).filter(element => Boolean(element));

export const getPinStart = () =>
  pinStartElements.reduce(
    (offset, elem) => offset + Math.max(elem.offsetHeight, elem.clientHeight),
    0
  ) + MISC_BAR_HEIGHT;
