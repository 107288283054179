import PropTypes from "prop-types";
import styles from "./index.css";
import FromLabel from "../FromLabel";

const ReducedPrice = ({ hasMultiplePrices, isMarkedDown, reducedPrice }) => (
  <span className={styles.reducedPrice}>
    {hasMultiplePrices && <FromLabel isMarkedDown={isMarkedDown} />}
    <span className={styles.saleAmount}>{reducedPrice}</span>
  </span>
);

export default ReducedPrice;

ReducedPrice.propTypes = {
  isMarkedDown: PropTypes.bool.isRequired,
  reducedPrice: PropTypes.string.isRequired,
  hasMultiplePrices: PropTypes.bool.isRequired
};
