import { useEffect, useState, useRef } from "react";
import videoUrlProvider from "./videoUrlProvider";
import logger from "../../../lib/logger";

const useVideoSrcDownloadOnMount = ({ videoUrl, onError, useHighBitrate }) => {
  const [videoSrc, setVideoSrc] = useState(null);
  const hasFetched = useRef(false);

  useEffect(() => {
    if (useHighBitrate !== null && !hasFetched.current) {
      hasFetched.current = true;

      (async () => {
        try {
          const src = await videoUrlProvider({
            rawManifestUrl: `//${videoUrl}`,
            useHighBitrate
          });
          setVideoSrc(src);
        } catch (error) {
          error.message = `getting video src for '${videoUrl}' failed: ${error.message}`;
          logger.error(error);
          onError();
        }
      })();
    }
  }, [useHighBitrate]);

  return videoSrc;
};

export default useVideoSrcDownloadOnMount;
