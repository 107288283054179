const CATEGORY_PREVIEW_TREATMENT_TYPE = "productListingPage";

const getCategoryPreviewDefinitions = treatments =>
  treatments?.configuration[0].treatmentDefinitions[0].featuredTreatments
    .filter(
      ({ treatmentType }) => treatmentType === CATEGORY_PREVIEW_TREATMENT_TYPE
    )
    .map(({ treatmentDefinitions: [treatment] }) => treatment) || [];

export default getCategoryPreviewDefinitions;
