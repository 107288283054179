import styles from "./CategoryPreviewGridSkeleton.css";
import classnames from "classnames";

const MAX_SKELETON_TILES = 8;

const CategoryPreviewGridSkeleton = () => (
  <div className={styles.container}>
    <div className={styles.header}>
      <div className={styles.title}>
        <div className={classnames(styles.placeholder, styles.titleLineOne)} />
        <div className={classnames(styles.placeholder, styles.titleLineTwo)} />
      </div>
    </div>
    <ul className={styles.productList}>
      {new Array(MAX_SKELETON_TILES).fill().map((_, index) => (
        <li key={index} className={styles.productTile}>
          <div
            className={classnames(styles.placeholder, styles.productImage)}
          />
          <div
            className={classnames(styles.placeholder, styles.productLineOne)}
          />
          <div
            className={classnames(styles.placeholder, styles.productLineTwo)}
          />
          <div
            className={classnames(styles.placeholder, styles.productLineThree)}
          />
        </li>
      ))}
    </ul>
  </div>
);

export default CategoryPreviewGridSkeleton;
