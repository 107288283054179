import LazyImage from "./LazyImage";
import LazyVideo from "./LazyVideo";
import EagerImage from "./EagerImage";
import EagerVideo from "./EagerVideo";

const INITIAL_MEDIA_LOAD_COUNT = 4;

const getSuitableElementType = ({
  showVideo,
  prefersReducedMotion,
  isInitialPage,
  index
}) => {
  const isDataSavingWanted = () => CLIENT && !!navigator.connection?.saveData;

  const shouldShowVideo = ({ showVideo, prefersReducedMotion }) =>
    showVideo && !prefersReducedMotion && !isDataSavingWanted();

  const shouldImmediatelyLoadMedia = ({ index, isInitialPage }) =>
    isInitialPage && index < INITIAL_MEDIA_LOAD_COUNT;

  const isVideo = shouldShowVideo({ showVideo, prefersReducedMotion });
  const isEager = shouldImmediatelyLoadMedia({ isInitialPage, index });

  if (isEager) {
    return isVideo ? EagerVideo : EagerImage;
  }

  return isVideo ? LazyVideo : LazyImage;
};

export default getSuitableElementType;
