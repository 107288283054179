import cookie from "js-cookie";

export const GALLERY_ONBOARD_DISMISSED_COOKIE_KEY =
  "plp_gallery_onboard_dismiss";

export function getImageGalleryOnboardDismissedFromCookie({ cookies }) {
  const imageGalleryOnboardDismissed =
    cookies[GALLERY_ONBOARD_DISMISSED_COOKIE_KEY] || false;

  return imageGalleryOnboardDismissed;
}

export function setImageGalleryOnboardDismissedFromCookie(
  imageGalleryOnboardDismissed
) {
  if (CLIENT) {
    cookie.set(
      GALLERY_ONBOARD_DISMISSED_COOKIE_KEY,
      imageGalleryOnboardDismissed,
      { expires: 1 }
    );
  }
}
