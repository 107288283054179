import { Component } from "react";
import PropTypes from "prop-types";
import WindowEventsListener from "../WindowEventsListener";

const isTouchOutside = (element, eventTarget) =>
  element && !element.contains(eventTarget);

class TouchOutside extends Component {
  shouldHandlerTrigger = event => {
    if (isTouchOutside(this.props.getElement(), event.target)) {
      this.props.outsideHandler();

      if (event.target?.dataset?.blockPropagation === "true") {
        event.preventDefault();
      }
    }
  };

  render() {
    return (
      <WindowEventsListener
        handler={this.shouldHandlerTrigger}
        events={["mousedown", "touchend"]}
        toDebounce={false}
      />
    );
  }
}

TouchOutside.propTypes = {
  getElement: PropTypes.func.isRequired,
  outsideHandler: PropTypes.func.isRequired
};

export default TouchOutside;
