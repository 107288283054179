import PropTypes from "prop-types";
import Headroom from "react-headroom";
import classNames from "classnames/bind";
import styles from "./index.css";
import FilterLayoutContainer from "../../containers/FilterLayout";
import SmallScreenSort from "../Sort/SmallScreenSort";
import RefinementListContainer from "../RefinementsList";
import RefinementListCloseButton from "../RefinementsList/CloseButton";
import getPinStart from "./getPinStart";

const combineClasses = classNames.bind(styles);

export const SmallScreenHeader = ({
  formatTranslation,
  showFilterOverlay,
  hasSelectedFacets
}) => (
  <div className={styles.container}>
    <Headroom
      className={styles.stickyHeader}
      disableInlineStyles={true}
      pinStart={getPinStart}
      upTolerance={17}
    >
      <div className={styles.sortControls}>
        <SmallScreenSort />
      </div>
      <div className={styles.filterControls}>
        <button
          className={combineClasses({
            filterButton: true,
            filterButton_checked: hasSelectedFacets
          })}
          onClick={showFilterOverlay}
        >
          {formatTranslation("plp_web_search_filter_label")}
        </button>
      </div>
    </Headroom>
    <FilterLayoutContainer>
      <RefinementListCloseButton />
      <RefinementListContainer />
    </FilterLayoutContainer>
  </div>
);

SmallScreenHeader.propTypes = {
  showFilterOverlay: PropTypes.func.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  hasSelectedFacets: PropTypes.bool.isRequired
};

export default SmallScreenHeader;
