import { Component } from "react";
import PropTypes from "prop-types";

/*
 * Maybe in the future instead of blocking the default behaviour of the browser we could use:
 *  scroll-boundary-behaviour: none //https://github.com/WICG/scroll-boundary-behavior
 *  or for windows:
 *  -ms-scroll-chaining: none;
 */
class ScrollVoid extends Component {
  constructor(props) {
    super(props);
    this.state = { isListening: false };
  }

  componentDidUpdate() {
    if (
      !this.state.isListening &&
      this.scrollEl.scrollHeight > this.scrollEl.clientHeight
    ) {
      this.scrollEl.addEventListener("wheel", this.scrollHandler);
      this.setState({
        ...this.state,
        isListening: true
      });

      return;
    }
    if (
      this.state.isListening &&
      this.scrollEl.scrollHeight <= this.scrollEl.clientHeight
    ) {
      this.scrollEl.removeEventListener("wheel", this.scrollHandler);
      this.setState({
        ...this.state,
        isListening: false
      });

      return;
    }
  }

  componentWillUnmount() {
    this.scrollEl.removeEventListener("wheel", this.scrollHandler);
    this.setState({
      ...this.state,
      isListening: false
    });
  }

  scrollHandler = event => {
    const { deltaY } = event;
    const { scrollEl } = this;

    event.preventDefault();
    if (event.deltaMode === WheelEvent.DOM_DELTA_PIXEL) {
      return (scrollEl.scrollTop += deltaY);
    }
    scrollEl.scrollTop += deltaY * 30;
  };

  setRef = scrollEl => {
    this.scrollEl = scrollEl;
  };

  render() {
    const { maxHeight, children, className } = this.props;

    return (
      <ul style={{ maxHeight }} ref={this.setRef} className={className}>
        {children}
      </ul>
    );
  }
}

ScrollVoid.propTypes = {
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default ScrollVoid;
