import PropTypes from "prop-types";
import hasAnySelectedValue from "./helpers/hasAnySelectedValue";

const ClearButton = ({ facets, className, onClick, children }) =>
  hasAnySelectedValue(facets) ? (
    <button data-auto-id="clear" className={className} onClick={onClick}>
      {children}
    </button>
  ) : null;

ClearButton.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  facets: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ClearButton;
