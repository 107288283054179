import PropTypes from "prop-types";
import classnames from "classnames/bind";
import styles from "./DropdownMenu.css";
import NewPill from "../../../containers/NewPill";

const combineClasses = classnames.bind(styles);

const DropdownMenu = ({
  id,
  name,
  children,
  click,
  isOpen,
  hasSelectedValues,
  isNew,
  haspopup
}) => (
  <div
    className={combineClasses({
      container: true,
      dropdown__open: isOpen
    })}
  >
    <button
      aria-haspopup={haspopup}
      aria-expanded={isOpen}
      className={combineClasses({
        button: true,
        highlighted: hasSelectedValues
      })}
      onClick={() => click(id, name)}
      data-filter-toggle="true"
    >
      <div className={styles.title}>{name}</div>
    </button>
    {isNew && <NewPill className={styles.newPill} />}
    <div
      className={combineClasses({
        collapsibleMenu: true,
        collapsibleMenu__open: isOpen
      })}
      data-filter-dropdown="true"
    >
      {children}
    </div>
  </div>
);

DropdownMenu.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  click: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  hasSelectedValues: PropTypes.bool,
  isNew: PropTypes.bool.isRequired,
  haspopup: PropTypes.oneOfType([
    PropTypes.oneOf(["listbox", true]).isRequired,
    PropTypes.bool.isRequired
  ])
};

export default DropdownMenu;
