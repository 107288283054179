import PropTypes from "prop-types";
import ProductImage from "../ProductImage";
import { useState } from "react";
import styles from "./index.css";
import classnames from "classnames";

const HoverableProductImage = ({ additionalImageUrls, ...rest }) => {
  const [hovered, setHovered] = useState(false);
  const [hoverImageLoaded, setHoverImageLoaded] = useState(false);
  const [hoverImage, setHoverImage] = useState(additionalImageUrls[0]);

  const onMouseOver = () => setHovered(true);
  const onMouseLeave = () => setHovered(false);

  return (
    <>
      <ProductImage {...{ onMouseOver, ...rest }} />
      {hoverImage && hovered ? (
        <ProductImage
          {...{
            onMouseLeave,
            onError: () => setHoverImage(null),
            onLoad: () => setHoverImageLoaded(true),
            className: classnames(styles.secondImage, {
              [styles.fade]: hoverImageLoaded
            }),
            image: hoverImage
          }}
        />
      ) : null}
    </>
  );
};

HoverableProductImage.propTypes = {
  additionalImageUrls: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default HoverableProductImage;
