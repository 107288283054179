import { ProgressIndicator } from "@asosteam/asos-web-component-library-progress-indicator";
import styles from "./AutoRetry.css";

const AutoRetry = () => (
  <div className={styles.container}>
    <ProgressIndicator />
  </div>
);

export default AutoRetry;
