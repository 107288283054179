import ProductTile from "./ProductTile.js";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import { sendPlacementOnViewBeacon } from "../../state/modules/search/actions";
import { anchorProductToUrl } from "../../state/modules/search/productTile/actions.js";
import { analyticsProductClick } from "../../state/modules/analytics/actions";
import { getViewportCategory } from "../../state/modules/layout/selectors";
import getBadges from "./Badges";
import TileContext from "../TileContext";

const ConnectedProductTile = props => {
  const dispatch = useDispatch();
  const viewportCategory = useSelector(getViewportCategory);
  const { tileSize } = useContext(TileContext);

  return (
    <ProductTile
      {...{
        ...props,
        ...getBadges({
          viewportCategory,
          tileSize
        }),
        sendPlacementOnViewBeacon: () => dispatch(sendPlacementOnViewBeacon()),
        analyticsProductClick: args => dispatch(analyticsProductClick(args)),
        anchorProductToUrl: args => dispatch(anchorProductToUrl(args))
      }}
    />
  );
};

export default require("../../containers/withTogglePoint").default(ConnectedProductTile, require.context("./__variants__/", true, /^\.\/([^/]+\/){2}index\.js$/))
