import { ANCHOR_PRODUCT } from "../constants";
import { getLocation } from "../../router/selectors";
import { updateLocationQuery } from "../../router/actions";
import { CAROUSEL_ID } from "../../../../components/UpSellCarousel/constants";

export const anchorProductToUrl = ({ productTileId, carouselCatId }) => (
  dispatch,
  getState
) => {
  dispatch({ type: ANCHOR_PRODUCT });

  const { query } = getLocation(getState());

  dispatch(
    updateLocationQuery({
      query: {
        ...query,
        scrollTo: carouselCatId ? CAROUSEL_ID : productTileId
      }
    })
  );
};
