import hashString from "./hashString";
import getCustomerId from "../../../client/identity/getCustomerId";

const getHashedCustomerIdPromise = async () => {
  const customerId = await getCustomerId();

  if (!customerId) return;

  return hashString(customerId);
};

export default getHashedCustomerIdPromise;
