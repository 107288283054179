import PropTypes from "prop-types";
import styles from "./index.css";
import addUpSellCarousel from "./addUpSellCarousel";
import addAdvert from "./addAdvert";
import getFocusTileIndex from "./getFocusTileIndex";
import mapProductsToProductTiles from "./mapProductsToProductTiles";
import addCategoryPreviews from "./addCategoryPreviews";

const ListingPage = ({
  isInitialPage,
  page: { products, pageNumber },
  focusFirstTile,
  focusLastTile,
  advert,
  upSellCarousel,
  treatments,
  viewportCategory,
  getAdditionalItemPositionFinder,
  ...rest
}) => {
  const focusTileIndex = getFocusTileIndex({
    focusFirstTile,
    focusLastTile,
    products
  });

  const initialProductsTilesArray = mapProductsToProductTiles({
    products,
    focusTileIndex,
    isInitialPage,
    pageNumber,
    ...rest
  });

  let items = [...initialProductsTilesArray];

  const positionFinder = getAdditionalItemPositionFinder({
    products,
    productTiles: initialProductsTilesArray,
    viewportCategory
  });

  items = addAdvert({
    advert,
    items,
    positionFinder
  });

  items = addUpSellCarousel({
    upSellCarousel,
    items,
    positionFinder
  });

  if (pageNumber === 1) {
    items = addCategoryPreviews({
      treatments,
      positionFinder,
      items,
      viewportCategory
    });
  }

  return (
    <section className={styles.listingPage} data-auto-id={pageNumber}>
      {items}
    </section>
  );
};

ListingPage.propTypes = {
  isInitialPage: PropTypes.bool.isRequired,
  page: PropTypes.shape({
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    pageNumber: PropTypes.number
  }),
  focusFirstTile: PropTypes.bool.isRequired,
  focusLastTile: PropTypes.bool.isRequired,
  advert: PropTypes.func,
  upSellCarousel: PropTypes.func,
  treatments: PropTypes.object,
  viewportCategory: PropTypes.string,
  getAdditionalItemPositionFinder: PropTypes.func.isRequired
};

export default ListingPage;
