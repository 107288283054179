import { createAction } from "redux-actions";
import {
  SHOW_FILTER_OVERLAY,
  HIDE_FILTER_OVERLAY,
  UPDATE_VIEWPORT_CATEGORY,
  UPDATE_CLIENT_WIDTH
} from "./constants";
import { analyticsOpenFilterSmall } from "../analytics/actions";
import { setViewportCategoryCookie } from "./columnCount";

export function showFilterOverlay() {
  return dispatch => {
    dispatch(createAction(SHOW_FILTER_OVERLAY)());
    dispatch(analyticsOpenFilterSmall());
  };
}

export const hideFilterOverlay = () => ({
  type: HIDE_FILTER_OVERLAY
});

export const updateViewportCategory = viewportCategory => {
  setViewportCategoryCookie(viewportCategory);

  return {
    type: UPDATE_VIEWPORT_CATEGORY,
    payload: {
      viewportCategory
    }
  };
};

export const updateClientWidth = clientWidth => ({
  type: UPDATE_CLIENT_WIDTH,
  payload: {
    clientWidth
  }
});
