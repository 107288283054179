import commonConfig from "../../config/common";

const getRankingDisclaimerLink = storeCode => {
  const { rankingDisclaimerLinks } = commonConfig;

  if (!rankingDisclaimerLinks) {
    return null;
  }

  const rankingDisclaimerLink = rankingDisclaimerLinks[storeCode];

  return rankingDisclaimerLink ? rankingDisclaimerLink : null;
};

export default getRankingDisclaimerLink;
