import PropTypes from "prop-types";
import LoadButton from "../Results/LoadButton";
import { decodeAndSortUrl } from "../../helpers/decodeAndSortUrl";
import styles from "./LoadPrevious.css";

const LoadPreviousButton = ({
  isLoadingPrevious,
  loadPrevious,
  previousPagePath,
  formatTranslation,
  formatLink
}) => (
  <LoadButton
    loadCallback={loadPrevious}
    isLoading={isLoadingPrevious}
    testId="loadPreviousProducts"
    linkUrl={decodeAndSortUrl(formatLink(previousPagePath))}
    additionalClasses={styles.loadPrevious}
    formatTranslation={formatTranslation}
  >
    {formatTranslation("plp_web_search_load_previous_button_text")}
  </LoadButton>
);

LoadPreviousButton.propTypes = {
  isLoadingPrevious: PropTypes.bool.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  formatLink: PropTypes.func.isRequired,
  loadPrevious: PropTypes.func.isRequired,
  previousPagePath: PropTypes.string.isRequired
};

export default LoadPreviousButton;
