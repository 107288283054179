import { useState } from "react";
import styles from "./SearchBox.css";
import classnames from "classnames";
import PropTypes from "prop-types";
import { withTranslation } from "../../translation";

const SearchBox = ({ searchTerm, onChange, onClear, formatTranslation }) => {
  const [value, setValue] = useState(searchTerm);
  const handleOnChange = e => {
    setValue(e.target.value);
    onChange(e);
  };
  const handleOnClear = e => {
    setValue("");
    onClear(e);
  };

  return (
    <>
      <input
        type="search"
        placeholder={formatTranslation(
          "plp_web_refinements_text_filter_search"
        )}
        aria-label={formatTranslation("plp_web_refinements_text_filter_search")}
        value={value}
        className={styles.textFilter}
        onChange={handleOnChange}
      />
      {searchTerm.length ? (
        <button
          className={classnames(styles.icon, styles.clearBtn)}
          onClick={handleOnClear}
          aria-label={formatTranslation("plp_web_refinements_list_clear")}
        />
      ) : (
        <span className={classnames(styles.icon, styles.searchIcon)} />
      )}
    </>
  );
};

SearchBox.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  formatTranslation: PropTypes.func.isRequired
};

export default withTranslation(SearchBox);
