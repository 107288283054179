import PropTypes from "prop-types";
import PriceRange from "./PriceRange";
import TextMultiSelectDropDown from "./TextMultiSelectDropDown";

const FacetValues = ({ facet, verticalDisplacement, ...props }) => {
  const { facetType, id } = facet;
  switch (facetType) {
    case "TextMultiSelect":
      return (
        <TextMultiSelectDropDown
          facet={facet}
          verticalDisplacement={verticalDisplacement}
          {...props}
        ></TextMultiSelectDropDown>
      );
    case "Range":
      if (id === "currentprice") {
        return <PriceRange facet={facet} {...props} />;
      }
  }

  return null;
};

FacetValues.propTypes = {
  facet: PropTypes.shape({
    facetType: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  selectAll: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  changeMultiFacet: PropTypes.func.isRequired,
  verticalDisplacement: PropTypes.number.isRequired
};

export default FacetValues;
