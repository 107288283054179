import { Component } from "react";
import PropTypes from "prop-types";

class Escapable extends Component {
  componentDidMount() {
    window.addEventListener("keyup", this.onKeyUp);
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.onKeyUp);
  }

  onKeyUp = event => {
    const isEscapeKey = event.key && event.key.startsWith("Esc");
    const isEscapeKeyFallback = event.which === 27;
    if (isEscapeKey || isEscapeKeyFallback) {
      this.props.escapeHandler();
    }
  };

  render() {
    return null;
  }
}

Escapable.propTypes = {
  escapeHandler: PropTypes.func.isRequired
};

export default Escapable;
