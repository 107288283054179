import getPositionInItemsArrayFromPositionInProductTilesArray from "./getPositionInItemsArrayFromPositionInProductTilesArray";
import getAddittionalSpaceOccupiedByFeaturedTiles from "./getAdditionalSpaceOccupiedByFeaturedTiles";

const getAdditionalItemPosition = state => ({
  products,
  productTiles,
  viewportCategory
}) => {
  const getUnadjustedPosition = getPositionInItemsArrayFromPositionInProductTilesArray(
    productTiles
  );

  const getPositionAdjustment = getAddittionalSpaceOccupiedByFeaturedTiles({
    products,
    state,
    viewportCategory
  });

  return ({ configuredPosition, items }) => {
    const unadjustedPosition = getUnadjustedPosition({
      positionInProductTilesArray: configuredPosition,
      items
    });

    const adjustedPosition =
      unadjustedPosition -
      getPositionAdjustment({
        endPosition: unadjustedPosition
      });

    return adjustedPosition;
  };
};

export default getAdditionalItemPosition;
