import PropTypes from "prop-types";
import { withTranslation } from "../translation";

export const Select = props => {
  const { formatTranslation } = props;

  const handleChange = ({ target }) => {
    if (target.value === props.selected) {
      return;
    }
    if (props.onSelect) {
      const selectedOption = target.value
        ? props.options.find(option => option.value === target.value)
        : props.options.find(option => option.value === undefined);
      props.onSelect(
        selectedOption.value,
        formatTranslation(selectedOption.label)
      );
    }
  };

  const selectTarget = props.options.find(
    ({ value }) => value === props.selected
  );

  const selected = selectTarget ? selectTarget.value : undefined;

  const options = props.options.map(({ value, label }, index) => {
    const optionValue = value === undefined ? "" : value;

    return (
      <option key={index} value={optionValue}>
        {formatTranslation(label)}
      </option>
    );
  });

  return (
    <select
      id={props.id}
      className={props.className}
      onBlur={handleChange}
      onChange={handleChange}
      value={selected}
    >
      {options}
    </select>
  );
};

Select.propTypes = {
  id: PropTypes.string,
  selected: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func,
  className: PropTypes.string,
  formatTranslation: PropTypes.func.isRequired
};

Select.displayName = "Select";

export default withTranslation(Select);
