import categoryPreviewFactory from "../../helpers/categoryPreviewFactory";
import getCategoryPreviewDefinitions from "./getCategoryPreviewDefinitions";
import getTilePositionFromRowPosition from "../../helpers/getTilePositionFromRowPosition";

const addCategoryPreviews = ({
  treatments,
  viewportCategory,
  positionFinder,
  items
}) => {
  const outputItems = [...items];

  getCategoryPreviewDefinitions(treatments).forEach(
    ({ view, categoryId, showAtRow, ...rest }) => {
      const CategoryPreview = categoryPreviewFactory(view);
      const targetPosition = getTilePositionFromRowPosition({
        viewportCategory,
        rowPosition: showAtRow
      });

      const targetIndexInItemsArray = positionFinder({
        configuredPosition: targetPosition,
        items: [...outputItems]
      });

      if (targetIndexInItemsArray === outputItems.length) {
        return outputItems;
      }

      outputItems.splice(
        targetIndexInItemsArray,
        0,
        <CategoryPreview
          key={`${view}-${categoryId}`}
          categoryId={categoryId}
          {...rest}
        />
      );
    }
  );

  return outputItems;
};

export default addCategoryPreviews;
