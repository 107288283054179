import setupConsent from "./setupConsent";
import scriptLoader from "./scriptLoader";
import setPublisherId from "./setPublisherId";

const ensurePageSetup = async ({
  scriptSrc,
  fetchMarginPercent,
  renderMarginPercent,
  mobileMarginScaling,
  hashedCustomerIdPromise
}) => {
  if (window.googletag === undefined) {
    window.googletag = { cmd: [] };
    const hashedCustomerId = await hashedCustomerIdPromise;

    window.googletag.cmd.unshift(() => {
      window.googletag.pubads().setForceSafeFrame(true).collapseEmptyDivs(true);
      window.googletag.pubads().enableLazyLoad({
        fetchMarginPercent,
        renderMarginPercent,
        mobileScaling: mobileMarginScaling
      });
      setupConsent();
      setPublisherId(hashedCustomerId);
    });
    scriptLoader({ scriptSrc });
  }
};

export default ensurePageSetup;
