import { useEffect } from "react";
import hasTargetingChanged from "./hasTargetingChanged";
import refreshSlot from "../refreshSlot";

const useSlotTargeting = ({ slot, targeting }) => {
  useEffect(() => {
    if (slot && hasTargetingChanged({ slot, targeting })) {
      slot.updateTargetingFromMap(targeting);
      refreshSlot({ slot });
    }
  }, [slot, targeting]);
};

export default useSlotTargeting;
