import { useSelector, useDispatch } from "react-redux";
import PromoLabelOnboardingMessage from "./PromoLabelOnboardingMessage";
import { updatePromoOnboardDismissed } from "../../../../../../state/modules/search/__variants__/plp-promo-label/actions";
import { getPromoOnboardDismissed } from "../../../../../../state/modules/search/__variants__/plp-promo-label/selectors";

const ConnectedPromoLabelOnboardingMessage = props => {
  const dispatch = useDispatch();
  const promoOnboardDismissed = useSelector(getPromoOnboardDismissed);

  return (
    <PromoLabelOnboardingMessage
      {...{
        ...props,
        promoOnboardDismissed,
        updatePromoOnboardDismissed: args =>
          dispatch(updatePromoOnboardDismissed(args))
      }}
    />
  );
};

export default ConnectedPromoLabelOnboardingMessage;
