import PropTypes from "prop-types";
import styles from "./index.css";
import { withTranslation } from "../../../../../translation";
import classnames from "classnames";

const CloseButton = ({ onClick, className, formatTranslation }) => (
  <button
    className={classnames(styles.closeButton, className)}
    onClick={onClick}
    aria-label={formatTranslation("accessibility_label_close")}
  />
);

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  formatTranslation: PropTypes.func.isRequired
};

export default withTranslation(CloseButton);
