import ErrorBoundary from "../../containers/ErrorBoundary";
import ErrorComponent from "./ErrorComponent";
import UpSellCarouselSkeleton from "./UpSellCarouselSkeleton";
import loadable from "@loadable/component";

const UpSellCarouselLoader = loadable(
  () =>
    import(
      /* webpackPreload: true, webpackChunkName: "UPSELL_CAROUSEL" */ "./UpSellCarouselContainer"
    ),
  { fallback: <UpSellCarouselSkeleton /> }
);

const UpSellCarousel = props => (
  <ErrorBoundary ErrorComponent={ErrorComponent}>
    <UpSellCarouselLoader {...props} />
  </ErrorBoundary>
);

export default UpSellCarousel;
