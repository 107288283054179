import PropTypes from "prop-types";
import styles from "./index.css";
import classnames from "classnames";

export const ProductMediaContainer = ({ className, children }) => (
  <div className={classnames(styles.productMediaContainer, className)}>
    {children}
  </div>
);

ProductMediaContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default ProductMediaContainer;
