import PropTypes from "prop-types";
import styles from "./index.css";
import SinglePageAppLink from "../../SinglePageAppLink";

const Pill = ({ friendlyName, linkUrl }) => (
  <li>
    <SinglePageAppLink href={linkUrl} className={styles.pill}>
      <>{friendlyName}</>
    </SinglePageAppLink>
  </li>
);

Pill.propTypes = {
  friendlyName: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  extraClassName: PropTypes.string
};

export default Pill;
