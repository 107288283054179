const getKey = (obj, v) => {
  switch (typeof v) {
    case "string":
      return obj[v];
    case "function":
      return v(obj);
    default:
      return;
  }
};

const groupBy = (items, v) =>
  items.reduce((res, item) => {
    const k = getKey(item, v);
    if (k) {
      res[k] = res[k] || [];
      res[k].push(item);
    }

    return res;
  }, {});

export default groupBy;
