import LoadPreviousButton from "./LoadPreviousButton";
import PropTypes from "prop-types";
import { withTranslation } from "../translation";
import { useDispatch, useSelector } from "react-redux";
import { loadPrevious } from "../../state/modules/search/actions";
import { getPreviousPagePath } from "../../state/modules/search/getPreviousPagePath";
import { getIsLoadingPrevious } from "../../state/modules/search/selectors";

const ConnectedLoadPreviousButton = ({ formatTranslation, formatLink }) => {
  const dispatch = useDispatch();

  return (
    <LoadPreviousButton
      isLoadingPrevious={useSelector(getIsLoadingPrevious)}
      loadPrevious={args => dispatch(loadPrevious(args))}
      previousPagePath={useSelector(getPreviousPagePath)}
      formatTranslation={formatTranslation}
      formatLink={formatLink}
    />
  );
};

ConnectedLoadPreviousButton.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  formatLink: PropTypes.func.isRequired
};

export default withTranslation(ConnectedLoadPreviousButton);
