import { useEffect } from "react";
import getIntersectionObserverCallback from "./getIntersectionObserverCallback";

const useOnViewBeacon = ({
  targetElement,
  onViewBeacon,
  sendPlacementOnViewBeacon
}) => {
  useEffect(() => {
    if (!targetElement || !onViewBeacon) {
      return;
    }

    const intersectionObserverCallback = getIntersectionObserverCallback({
      onViewBeacon,
      sendPlacementOnViewBeacon
    });

    const intersectionObserverOptions = {
      threshold: 0.5
    };

    const intersectionObserver = new IntersectionObserver(
      intersectionObserverCallback,
      intersectionObserverOptions
    );

    intersectionObserver.observe(targetElement);
  }, [targetElement, onViewBeacon]);
};

export default useOnViewBeacon;
