import { getPaginationData } from "./selectors";
import { getLocation } from "../router/selectors";
import filterUrlForSeo from "../../../helpers/filterUrlForSeo";

export const NEXT_PAGE = "NEXT_PAGE";
export const PREVIOUS_PAGE = "PREVIOUS_PAGE";

const getNextPage = (pagination, direction) => {
  const [attr, fallback] =
    direction === NEXT_PAGE ? ["next", "max"] : ["previous", "initial"];

  return pagination.page[attr] || pagination.page[fallback];
};

export const getPagePath = ({ state, direction }) => {
  const { pathname, query } = getLocation(state);
  const pagination = getPaginationData(state);

  const qs = direction
    ? { ...query, page: getNextPage(pagination, direction) }
    : query;

  const queryString = new URLSearchParams(qs).toString();

  const url = `${pathname}?${queryString}`;

  return filterUrlForSeo(url);
};

export const buildPagePathWithQuery = (location, query) => {
  const queryString = new URLSearchParams({
    ...location.query,
    ...query
  }).toString();

  return `${location.pathname}?${decodeURIComponent(queryString)}`;
};
