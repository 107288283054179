import PropTypes from "prop-types";
import ProductDeal from "./ProductDeal";
import ProductSponsored from "./ProductSponsored";
import styles from "../ProductTile.css";

const Banners = ({ deal, dealAria, isSponsored, labels }) => (
  <>
    {deal !== null && (
      <ProductDeal
        deal={deal}
        dealAria={dealAria}
        className={styles.topLeftBanner}
        labelledById={labels.deal}
      />
    )}
    {isSponsored && (
      <ProductSponsored
        className={styles.topRightBanner}
        labelledById={labels.sponsored}
      />
    )}
  </>
);

Banners.propTypes = {
  deal: PropTypes.string,
  dealAria: PropTypes.string,
  isSponsored: PropTypes.bool.isRequired,
  labels: PropTypes.shape({
    deal: PropTypes.string,
    sponsored: PropTypes.string
  })
};

export default Banners;
