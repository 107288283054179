import { UPDATE_IMAGE_GALLERY_DISMISS } from "./constants";
import { setImageGalleryOnboardDismissedFromCookie } from "./imageGalleryOnboardDismissed";

export const updateImageGalleryOnboardDismissed = imageGalleryOnboardDismissed => {
  setImageGalleryOnboardDismissedFromCookie(imageGalleryOnboardDismissed);

  return {
    type: UPDATE_IMAGE_GALLERY_DISMISS,
    payload: {
      imageGalleryOnboardDismissed
    }
  };
};
