import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const sanitiseUrl = path => {
  const url = new URL(path, "https://www.asos.com/");
  const { pathname, search, hash } = url;

  return {
    pathname,
    search,
    hash
  };
};

const SinglePageAppLink = ({ href, children, setIsNewPage, ...props }) => {
  const { pathname, search, hash } = sanitiseUrl(href);

  return (
    <Link
      onClick={setIsNewPage}
      to={{
        pathname,
        search,
        hash
      }}
      {...props}
    >
      {children}
    </Link>
  );
};

SinglePageAppLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  setIsNewPage: PropTypes.func.isRequired
};

export default SinglePageAppLink;
