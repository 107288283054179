import PropTypes from "prop-types";
import { connect } from "react-redux";
import { hideFilterOverlay } from "../../../state/modules/layout/actions";
import { analyticsRefinementsCloseMobile } from "../../../state/modules/analytics/actions";
import styles from "./index.css";
import { withTranslation } from "../../translation";

export const RefinementListCloseButton = ({
  hideFilterOverlay,
  analyticsRefinementsCloseMobile,
  formatTranslation
}) => {
  const handleClick = () => {
    hideFilterOverlay();
    analyticsRefinementsCloseMobile();
  };

  return (
    <button
      className={styles.refinementListCloseButton}
      onClick={handleClick}
      aria-label={formatTranslation("accessibility_label_close")}
    />
  );
};

const mapDispatchToProps = {
  hideFilterOverlay,
  analyticsRefinementsCloseMobile
};

RefinementListCloseButton.propTypes = {
  hideFilterOverlay: PropTypes.func.isRequired,
  analyticsRefinementsCloseMobile: PropTypes.func.isRequired,
  formatTranslation: PropTypes.func.isRequired
};

RefinementListCloseButton.displayName = "RefinementListCloseButton";

export default connect(
  null,
  mapDispatchToProps
)(withTranslation(RefinementListCloseButton));
