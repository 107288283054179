import getExecute from "./getExecute";

const getIntersectionObserverCallback = ({
  onViewBeacon,
  sendPlacementOnViewBeacon
}) => {
  let intersectionTimeout;

  return ([{ isIntersecting }], intersectionObserver) => {
    if (isIntersecting) {
      const execute = getExecute({
        intersectionObserver,
        onViewBeacon,
        sendPlacementOnViewBeacon
      });

      intersectionTimeout = setTimeout(execute, 1000);

      return;
    }

    clearTimeout(intersectionTimeout);
  };
};

export default getIntersectionObserverCallback;
