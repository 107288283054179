import PropTypes from "prop-types";
import styles from "./index.css";
import { withTranslation } from "../../../../../../translation";

const CloseButton = ({ onClick, formatTranslation }) => (
  <button
    className={styles.closeButton}
    onClick={onClick}
    aria-label={formatTranslation(
      "plp_onboarding_promo_label_close_button_accessibility"
    )}
  />
);

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  formatTranslation: PropTypes.func.isRequired
};

export default withTranslation(CloseButton);
