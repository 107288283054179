import PropTypes from "prop-types";
import useAdvert from "./useAdvert";

const GoogleAds = ({ className }) => {
  const id = useAdvert();

  return <div id={id} className={className} />;
};

GoogleAds.propTypes = {
  className: PropTypes.string
};

export default GoogleAds;
