import PropTypes from "prop-types";
import styles from "./RefinementItemRange.css";

const RefinementItemRange = ({ facet, range, formatCurrency }) => (
  <span>
    {facet.name}
    {range.hasSelectedValues ? (
      <span className={styles.facetValues}>
        {formatCurrency(range.value.min)} - {formatCurrency(range.value.max)}
      </span>
    ) : (
      ""
    )}
  </span>
);

RefinementItemRange.propTypes = {
  range: PropTypes.object.isRequired,
  facet: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  formatCurrency: PropTypes.func.isRequired
};

export default RefinementItemRange;
