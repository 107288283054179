import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./index.css";

const NewPill = ({ formatTranslation, className }) => (
  <div className={classnames(styles.pill, className)}>
    {formatTranslation("plp_web_refinements_list_feature_new_tag")}
  </div>
);

NewPill.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default NewPill;
