import {
  useAdvert as useGoogleAdvert,
  useAdverts as useGoogleAdverts
} from "../../../client/googleAdverts";
import { useSelector } from "react-redux";
import {
  getAppLevelTargeting,
  getAdSpecificTargeting,
  getValidSlotSizes,
  getPriorProductsFingerprint,
  getAdUnitPath,
  getPageLevelTargeting
} from "../../../state/modules/adverts";
import config from "../../../config/common";
import getHashedCustomerIdPromise from "./getHashedCustomerIdPromise";

const useAdvert = () => {
  const {
    adverts: {
      adId: id,
      scriptSrc,
      sizeMappings,
      fetchMarginPercent,
      renderMarginPercent,
      mobileMarginScaling
    }
  } = config;

  const adUnitPath = useSelector(getAdUnitPath);
  if (!adUnitPath) {
    return null;
  }

  if (CLIENT) {
    const appLevelTargeting = useSelector(getAppLevelTargeting);
    const hashedCustomerIdPromise = getHashedCustomerIdPromise();
    const pageLevelTargeting = useSelector(getPageLevelTargeting);
    const targeting = { ...appLevelTargeting, ...pageLevelTargeting };
    useGoogleAdverts({
      scriptSrc,
      fetchMarginPercent,
      renderMarginPercent,
      mobileMarginScaling,
      targeting,
      hashedCustomerIdPromise
    });

    const validSlotSizes = useSelector(getValidSlotSizes);
    const priorProductsFingerprint = useSelector(getPriorProductsFingerprint);
    const advertSpecificTargeting = useSelector(getAdSpecificTargeting);
    useGoogleAdvert({
      id,
      sizeMappings,
      adUnitPath,
      targeting: advertSpecificTargeting,
      validSlotSizes,
      priorSiblingsFingerprint: priorProductsFingerprint
    });
  }

  return id;
};

export default useAdvert;
