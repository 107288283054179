/* istanbul ignore file */
/* This is not possible to test this in React Testing Libary*/
import {
  CATEGORY_BANNER_ID,
  SEARCH_TERM_ID,
  CHROME_BREADCRUMB_ID
} from "../../components/constants";

const pinStartElements = (CLIENT
  ? [
      document.getElementById(CHROME_BREADCRUMB_ID),
      document.getElementById(CATEGORY_BANNER_ID),
      document.getElementById(SEARCH_TERM_ID)
    ]
  : []
).filter(elem => !!elem);

const getPinStart = () =>
  pinStartElements.reduce(
    (offset, elem) => offset + Math.max(elem.offsetHeight, elem.clientHeight),
    0
  );

export default getPinStart;
