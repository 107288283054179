import logger from "../lib/logger";

export const decodeAndSortUrl = url => {
  try {
    const urlObj = new URL(url);
    const params = urlObj.searchParams;
    params.sort();

    let paramsString = params.toString();

    if (paramsString) {
      paramsString = `?${paramsString}`;
    }

    return decodeURIComponent(
      `${urlObj.origin}${urlObj.pathname}${paramsString}`
    );
  } catch (error) {
    error.message = `invalid url: ${url} ${error.message}`;
    logger.error(error);

    return url;
  }
};
