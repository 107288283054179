import { viewportCategoryToColumnCount } from "../../state/modules/layout/columnCount";
import {
  TWO_COLUMNS,
  THREE_COLUMNS,
  FOUR_COLUMNS
} from "../../state/modules/layout/constants";

const COLUMN_COUNT_TO_NUMBER = {
  [TWO_COLUMNS]: 2,
  [THREE_COLUMNS]: 3,
  [FOUR_COLUMNS]: 4
};

const getTilePositionFromRowPosition = ({ viewportCategory, rowPosition }) => {
  const columnCount = viewportCategoryToColumnCount(viewportCategory);

  return COLUMN_COUNT_TO_NUMBER[columnCount] * (rowPosition - 1);
};

export default getTilePositionFromRowPosition;
