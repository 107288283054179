import PropTypes from "prop-types";
import styles from "./Label.css";
import ColourSwatch from "./ColourSwatch";
import classnames from "classnames";

const Label = ({
  uid,
  hasSwatches,
  swatchColour,
  facetValueName,
  classNames,
  count,
  isSelected
}) => (
  <label
    className={classnames(styles.label, classNames, {
      [styles.selected]: isSelected
    })}
    htmlFor={uid}
  >
    {hasSwatches ? (
      <ColourSwatch
        classNames={swatchColour ? null : styles.emptySwatch}
        backgroundColour={swatchColour}
      ></ColourSwatch>
    ) : null}
    <div className={styles.value}>
      {facetValueName}
      <span className={styles.refinementCount}>{`(${count})`}</span>
    </div>
  </label>
);

Label.propTypes = {
  uid: PropTypes.string.isRequired,
  hasSwatches: PropTypes.bool,
  swatchColour: PropTypes.string,
  facetValueName: PropTypes.string.isRequired,
  count: PropTypes.string.isRequired,
  classNames: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired
};

export default Label;
