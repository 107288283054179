import removeAccents from "remove-accents";

const normalizeString = string => removeAccents(string.toLowerCase().trim());

export const getFilteredFacetValues = ({ facetValues, searchTerm }) => {
  const normalizedSearchTerm = normalizeString(searchTerm);

  if (!normalizedSearchTerm.length) {
    return facetValues;
  }

  return facetValues.filter(({ name }) =>
    normalizeString(name).includes(normalizedSearchTerm)
  );
};
