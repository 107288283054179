import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import Results from "../Results";
import ErrorAlert from "../../containers/ErrorAlert";
import LayoutManagerContainer from "../../containers/LayoutManager";
import SearchTerm from "../SearchTerm";
import styles from "./ProductListingPage.css";
import Facets from "../Facets";
import CategoryRefinementLinks from "../CategoryRefinementLinks";
import { DELIMITER } from "../ProductTile/ariaConstants";

const styleContext = classNames.bind(styles);

export const ProductListingPage = props => {
  const {
    hasFacets,
    isNewSearch,
    location: { search },
    viewportCategory
  } = props;
  const queryStringRef = useRef(search);
  const { current: queryString } = queryStringRef;

  useEffect(() => {
    if (queryString !== search) {
      queryStringRef.current = search;

      if (isNewSearch) {
        window.scrollTo(0, 0);
      }
    }
  }, [search]);

  return (
    <div
      className={styleContext({
        container: true,
        wrapper: hasFacets
      })}
    >
      <LayoutManagerContainer />
      <SearchTerm />
      {hasFacets && <Facets viewportCategory={viewportCategory} />}
      <Results {...props} />
      <CategoryRefinementLinks />
      <ErrorAlert />
      <span className={styles.ariaDelimiter} id={DELIMITER}>
        ;
      </span>
    </div>
  );
};

ProductListingPage.propTypes = {
  isNewSearch: PropTypes.bool,
  location: PropTypes.object.isRequired,
  hasFacets: PropTypes.bool.isRequired,
  viewportCategory: PropTypes.string.isRequired
};

export default ProductListingPage;
