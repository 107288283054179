import PropTypes from "prop-types";
import TextMultiSelect from "./TextMultiSelect";
import FacetValueGroup from "./FacetValueGroup";
import groupBy from "../../../helpers/groupBy";
import PriceRange from "./PriceRange";
import styles from "./FacetValues.css";

const NonBrandMultiSelect = ({ facet, changeMultiFacet }) => (
  <div>
    <ul className={styles.ul}>
      {facet.facetValues.map(facetValue => (
        <li className={styles.li} key={facetValue.id}>
          <TextMultiSelect
            facet={facet}
            changeMultiFacet={changeMultiFacet}
            facetValue={facetValue}
          />
        </li>
      ))}
    </ul>
  </div>
);

NonBrandMultiSelect.propTypes = {
  facet: PropTypes.shape({
    facetValues: PropTypes.arrayOf(PropTypes.object).isRequired
  }).isRequired,
  changeMultiFacet: PropTypes.func.isRequired
};

const BrandMultiSelect = ({ facet, changeMultiFacet }) => (
  <div>
    {Object.entries(
      groupBy(facet.facetValues, facet => facet.name.charAt(0).toUpperCase())
    ).map(([title, group]) => (
      <FacetValueGroup
        key={`${facet.id}-${title}`}
        title={title}
        facet={facet}
        groupedFacetValues={group}
        changeMultiFacet={changeMultiFacet}
      />
    ))}
  </div>
);

BrandMultiSelect.propTypes = {
  facet: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    facetValues: PropTypes.arrayOf(PropTypes.object).isRequired
  }).isRequired,
  changeMultiFacet: PropTypes.func.isRequired
};

const FacetValues = ({ facet, changeMultiFacet }) => {
  const { id, facetType } = facet;

  if (facetType === "TextMultiSelect") {
    return id === "brand"
      ? BrandMultiSelect({ facet, changeMultiFacet })
      : NonBrandMultiSelect({ facet, changeMultiFacet });
  }

  if (id === "currentprice") {
    return <PriceRange facet={facet} />;
  }

  return null;
};

FacetValues.propTypes = {
  facet: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    facetType: PropTypes.string.isRequired,
    facetValues: PropTypes.arrayOf(PropTypes.object).isRequired
  }).isRequired,
  changeMultiFacet: PropTypes.func.isRequired
};

export default FacetValues;
