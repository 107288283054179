import PropTypes from "prop-types";
import styles from "./SuggestedTerm.css";
import { connect } from "react-redux";
import { withTranslation } from "../../translation/";
import { getSuggestTerms, isExactMatch } from "../../../state/modules/search";

export const SuggestedTerm = ({
  suggestedTerms,
  isExactMatch,
  formatTranslation
}) => {
  if (isExactMatch) {
    return null;
  }

  return (
    <p className={styles.alternativeText}>
      {formatTranslation("plp_web_suggested_term_suggestion_alternative")}
      <span className={styles.suggestedTerm}>
        {formatTranslation("plp_web_suggested_term_search_used_term", {
          suggestedTerm: suggestedTerms[0]
        })}
      </span>
    </p>
  );
};

SuggestedTerm.propTypes = {
  suggestedTerms: PropTypes.arrayOf(PropTypes.string).isRequired,
  isExactMatch: PropTypes.bool.isRequired,
  formatTranslation: PropTypes.func.isRequired
};

SuggestedTerm.displayName = "SuggestedTerm";

function mapStateToProps(state) {
  return {
    isExactMatch: isExactMatch(state),
    suggestedTerms: getSuggestTerms(state)
  };
}

export default connect(mapStateToProps)(withTranslation(SuggestedTerm));
