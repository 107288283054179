import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InputRange from "asos-web-react-input-range";
import { withTranslation } from "../translation";
import {
  changeRangeFacet,
  applyRangeFacet,
  getStateForPriceSlider
} from "../../state/modules/search";
import styles from "./index.css";

export const PriceSlider = ({
  priceRange: { minValue, maxValue, step, value },
  facet,
  applyRangeFacet,
  formatCurrency,
  formatTranslation,
  changeRangeFacet
}) => {
  const handleChangeComplete = () => applyRangeFacet(facet);

  return (
    <InputRange
      maxAriaLabel={formatTranslation("plp_web_a11y_maximum_price")}
      minAriaLabel={formatTranslation("plp_web_a11y_minimum_price")}
      minValue={Math.max(0, minValue)}
      maxValue={Math.max(1, maxValue)}
      step={step}
      value={{
        min: Math.max(0, value.min),
        max: Math.max(1, value.max)
      }}
      formatLabel={value => formatCurrency(value, true)}
      onChange={value => {
        changeRangeFacet(facet.id, {
          leftIndicator: value.min,
          rightIndicator: value.max
        });
      }}
      onChangeComplete={handleChangeComplete}
      classNames={styles}
    />
  );
};

PriceSlider.propTypes = {
  facet: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  changeRangeFacet: PropTypes.func.isRequired,
  applyRangeFacet: PropTypes.func.isRequired,
  formatCurrency: PropTypes.func.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  priceRange: PropTypes.shape({
    minValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    value: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired
    })
  })
};

PriceSlider.displayName = "PriceSlider";

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeRangeFacet,
      applyRangeFacet
    },
    dispatch
  );
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    priceRange: getStateForPriceSlider(state)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(PriceSlider));
