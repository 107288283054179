import config from "../../config/common";
import { Fragment } from "react";

const addUpSellCarousel = ({ upSellCarousel, items, positionFinder }) => {
  const {
    upsell: { carouselPosition }
  } = config;

  if (!upSellCarousel) {
    return [...items];
  }

  const positionInItemsArray = positionFinder({
    configuredPosition: carouselPosition,
    items
  });

  return [
    ...items.slice(0, positionInItemsArray),
    <Fragment key="upSellCarousel">{upSellCarousel}</Fragment>,
    ...items.slice(positionInItemsArray)
  ];
};

export default addUpSellCarousel;
