import PropTypes from "prop-types";
import { useContext } from "react";
import ProductHero from "../ProductHero";
import styles from "./index.css";
import TileContext from "../../TileContext";
import classnames from "classnames";
import { TILE_SIZES } from "../../../helpers/constants";

const { TILE_SIZE_TWO_BY_ONE, TILE_SIZE_TWO_BY_TWO } = TILE_SIZES;

const ProductMedia = props => {
  const { tileSize, heroMedia } = useContext(TileContext);

  if (!heroMedia) {
    return <ProductHero {...props} />;
  }

  return (
    <div
      className={classnames(styles.productMedia, {
        [styles.twoByOne]: tileSize === TILE_SIZE_TWO_BY_ONE,
        [styles.twoByTwo]: tileSize === TILE_SIZE_TWO_BY_TWO
      })}
    >
      {heroMedia.map((media, index) => (
        <ProductHero
          key={index}
          {...{
            ...props,
            ...media
          }}
        ></ProductHero>
      ))}
    </div>
  );
};

ProductMedia.propTypes = {
  image: PropTypes.string.isRequired,
  additionalImageUrls: PropTypes.arrayOf(PropTypes.string),
  videoUrl: PropTypes.string
};

export default ProductMedia;
