import PropTypes from "prop-types";
import styles from "./NoFilterSearchBoxMatches.css";

export const NoFilterSearchBoxMatches = ({
  isFiltered,
  filteredFacetValues,
  formatTranslation
}) => {
  if (!isFiltered || filteredFacetValues.length) {
    return null;
  }

  return (
    <li className={styles.noFilterSearchBoxMatches}>
      {formatTranslation("plp_web_refinements_text_filter_no_matches")}
    </li>
  );
};

NoFilterSearchBoxMatches.propTypes = {
  isFiltered: PropTypes.bool,
  filteredFacetValues: PropTypes.array.isRequired,
  formatTranslation: PropTypes.func.isRequired
};
