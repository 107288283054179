import {
  getClientPageLoadData,
  getPageSpecificAnalyticsData
} from "../../../pageLoad";

import {
  TRACKING_CONTEXT_IMAGE_SCROLL,
  TRACKING_KEY_IMAGE_SCROLL
} from "./constants";
import { ANALYTICS_IMAGE_SCROLLED } from "../../../../state/modules/analytics/actions/__variants__/plp-mweb-image-gallery/v2/constants";

import emitterEvents from "../../../emitterEvents";

const buildImageScrolledEvent = async (productId, state) => [
  TRACKING_KEY_IMAGE_SCROLL,
  {
    context: TRACKING_CONTEXT_IMAGE_SCROLL,
    productId,
    ...(await getClientPageLoadData(state)),
    ...getPageSpecificAnalyticsData(state)
  }
];

emitterEvents[ANALYTICS_IMAGE_SCROLLED] = (action, state) =>
  buildImageScrolledEvent(action.payload.productId, state);

export default emitterEvents;
