import PropTypes from "prop-types";
import { withTranslation } from "../../../translation";

const SellingFastBadge = ({
  isSellingFast,
  formatTranslation,
  labels: { sellingFast: labelledById },
  className
}) => {
  if (!isSellingFast) {
    return null;
  }

  return (
    <div
      id={labelledById}
      aria-hidden={true}
      aria-label={formatTranslation("va_selling_fast_label")}
      className={className}
    >
      {formatTranslation("selling_fast_label")}
    </div>
  );
};

SellingFastBadge.propTypes = {
  isSellingFast: PropTypes.bool.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    sellingFast: PropTypes.string.isRequired
  }).isRequired,
  className: PropTypes.string
};

export default withTranslation(SellingFastBadge);
