import sdkProvider from "../sdkProvider";
import config from "../../config/common";

export const saveProductForLater = async ({ productId, colourWayId }) => {
  const sdk = await sdkProvider("savedItems");

  return sdk.addItem({
    item: { productId, colourWayId },
    requestOptions: { timeout: config.api.savedLists.timeout }
  });
};
