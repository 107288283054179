import PropTypes from "prop-types";
import styles from "./index.css";
import { forwardRef } from "react";
import classnames from "classnames";

const ProductHeroContainer = forwardRef(
  ({ children, className }, ref = null) => (
    <div
      className={classnames(styles.productHeroContainer, className)}
      ref={ref}
    >
      {children}
    </div>
  )
);

ProductHeroContainer.displayName = "ProductHeroContainer";

ProductHeroContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  className: PropTypes.string
};

export default require("../../containers/withTogglePoint").default(ProductHeroContainer, require.context("./__variants__/", true, /^\.\/([^/]+\/){2}index\.js$/))
