import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { APP } from "@asosteam/asos-web-adverts-sdk";
import GoogleAds from "../GoogleAds";
import CriteoAds from "../CriteoAds";
import { getIsSearchPage } from "../../state/modules/page/selectors";
import getAdvertsSdk from "../../client/adverts/getAdvertsSdk";
import styles from "./index.css";
import classnames from "classnames";

const Advert = ({ className }) => {
  const [googleAdvertsEnabled, setGoogleAdvertsEnabled] = useState(false);
  const app = useSelector(getIsSearchPage) ? APP.SEARCH : APP.CATEGORY;

  useEffect(() => {
    const isGoogleAdvertsEnabled = async () => {
      const sdk = await getAdvertsSdk();
      setGoogleAdvertsEnabled(
        await sdk.isGoogleAdvertsEnabled({
          app
        })
      );
    };

    isGoogleAdvertsEnabled();
  }, []);

  return (
    <div className={classnames(styles.advert)}>
      {googleAdvertsEnabled && <GoogleAds className={className} />}
      <CriteoAds className={className} />
    </div>
  );
};

Advert.propTypes = {
  className: PropTypes.string,
  hidden: PropTypes.bool
};

export default Advert;
