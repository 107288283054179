const setupSlot = ({ id, sizeMappings, adUnitPath, targeting }) =>
  new Promise(resolve => {
    window.googletag.cmd.push(() => {
      const [
        {
          slotSizes: [mobileSlotSize]
        }
      ] = sizeMappings.slice(-1);
      const fallbackSlotSize = mobileSlotSize;

      const sizeMapping = sizeMappings.map(({ viewPortSize, slotSizes }) => [
        viewPortSize,
        slotSizes
      ]);

      const slot = window.googletag
        .defineSlot(adUnitPath, fallbackSlotSize, id)
        .defineSizeMapping(sizeMapping)
        .updateTargetingFromMap(targeting)
        .addService(window.googletag.pubads());
      window.googletag.display(slot);

      resolve(slot);
    });
  });

export default setupSlot;
