import PropTypes from "prop-types";
import styles from "./NotFoundMessage.css";

const NotFoundMessage = ({ errorMessage, suggestionMessage }) => (
  <div className={styles.wrapper}>
    <p className={styles.errorMessage}>{errorMessage}</p>
    <p className={styles.suggestionMessage}>{suggestionMessage}</p>
  </div>
);

NotFoundMessage.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  suggestionMessage: PropTypes.string.isRequired
};

export default NotFoundMessage;
