const debounce = (fn, wait) => {
  let timeout;

  const clear = () => {
    clearTimeout(timeout);
    timeout = null;
  };

  const debounceFunc = (...args) => {
    const later = () => {
      timeout = null;
      fn.apply(undefined, args);
    };
    clear();
    timeout = setTimeout(later, wait);
  };

  debounceFunc.clear = clear;

  return debounceFunc;
};

export default debounce;
