import { useSelector } from "react-redux";
import {
  getItemCount,
  hasFacets as getHasFacets
} from "../../state/modules/search";
import { getError } from "../../state/modules/error";
import {
  getIsNewSearch,
  getIsLoading
} from "../../state/modules/search/selectors";
import ProductListingPage from "../../components/ProductListingPage";
import useExperimentalParamsActivation from "./useExperimentalParamsActivation";
import { useConfigByStore, useConfigByCountry } from "../../components/hooks";
import {
  EU_OMNIBUS_COUNTRIES_KEY,
  DEAL_BANNER_STORES_KEY
} from "../../config/constants";
import { getViewportCategory } from "../../state/modules/layout/selectors";

const ConnectedProductListingPage = ownProps => {
  useExperimentalParamsActivation();

  const itemCount = useSelector(getItemCount);
  const error = useSelector(getError);
  const isNewSearch = useSelector(getIsNewSearch);
  const hasFacets = useSelector(getHasFacets);
  const isLoading = useSelector(getIsLoading);
  const viewportCategory = useSelector(getViewportCategory);

  const euOmnibusEnabled = useConfigByCountry({
    configKey: EU_OMNIBUS_COUNTRIES_KEY
  });
  const dealBannerEnabled = useConfigByStore({
    configKey: DEAL_BANNER_STORES_KEY
  });

  const props = {
    itemCount,
    error,
    isNewSearch,
    hasFacets,
    isLoading,
    dealBannerEnabled,
    euOmnibusEnabled,
    viewportCategory
  };

  return <ProductListingPage {...{ ...ownProps, ...props }} />;
};

export default ConnectedProductListingPage;
