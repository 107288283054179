import getProductBeaconsMapFromStorage from "./getProductBeaconsMapFromStorage";
import { PRODUCT_BEACONS_MAP_KEY } from "./constants";

const saveBeaconsInStorage = ({ productId, onClickBeacon }) => {
  const productBeaconsMap = getProductBeaconsMapFromStorage();

  if (!onClickBeacon) {
    delete productBeaconsMap[productId];
  } else {
    productBeaconsMap[productId] = {
      onClickBeacon
    };
  }

  sessionStorage.setItem(
    PRODUCT_BEACONS_MAP_KEY,
    JSON.stringify(productBeaconsMap)
  );
};

export default saveBeaconsInStorage;
