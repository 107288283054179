import { useEffect } from "react";

const useOnLoadBeacon = ({ onLoadBeacon }) => {
  useEffect(() => {
    if (!onLoadBeacon) {
      return;
    }

    navigator.sendBeacon(onLoadBeacon);
  }, [onLoadBeacon]);
};

export default useOnLoadBeacon;
