import PropTypes from "prop-types";
import { withTranslation } from "../../../translation";

const RestockingSoonBadge = ({
  isRestockingSoon,
  formatTranslation,
  labels: { restockingSoon: labelledById },
  className
}) => {
  if (!isRestockingSoon) {
    return null;
  }

  return (
    <div
      id={labelledById}
      aria-hidden={true}
      aria-label={formatTranslation("back_in_stock_soon_label")}
      className={className}
    >
      {formatTranslation("back_in_stock_soon_label")}
    </div>
  );
};

RestockingSoonBadge.propTypes = {
  isRestockingSoon: PropTypes.bool.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    restockingSoon: PropTypes.string.isRequired
  }).isRequired,
  className: PropTypes.string
};

export default withTranslation(RestockingSoonBadge);
