import PropTypes from "prop-types";
import SmallScreenHeader from "../../containers/SmallScreenHeader";
import MediumLargeScreenHeader from "../../containers/MediumLargeScreenHeader";
import { SMALL_VIEWPORT } from "../../state/modules/layout/constants";

const Facets = ({ viewportCategory }) => (
  <>
    {viewportCategory === SMALL_VIEWPORT ? (
      <SmallScreenHeader />
    ) : (
      <MediumLargeScreenHeader />
    )}
  </>
);

Facets.propTypes = {
  viewportCategory: PropTypes.string.isRequired
};

export default Facets;
