import PropTypes from "prop-types";
import media from "../../customMedia.json";
import { useMatchMedia } from "../../hooks";
import HoverableProductImage from "../../HoverableProductImage";
import ProductImage from "../../ProductImage";
import getSuitableElementType from "./getSuitableElementType";

const {
  customMedia: { ["--medium-viewport"]: mediumViewport }
} = media;

const getImageComponent = ({ prefersReducedMotion, hasAdditionalImages }) => {
  if (prefersReducedMotion || !hasAdditionalImages) {
    return ProductImage;
  }

  return HoverableProductImage;
};

const ProductHero = ({
  isInitialPage,
  index,
  showVideo,
  additionalImageUrls,
  ...rest
}) => {
  const isMediumViewportOrGreater = useMatchMedia(mediumViewport);
  const prefersReducedMotion = useMatchMedia("(prefers-reduced-motion)");
  const hasAdditionalImages = additionalImageUrls !== null;
  const props = {
    ImageComponent: getImageComponent({
      prefersReducedMotion,
      hasAdditionalImages
    }),
    isMediumViewportOrGreater,
    additionalImageUrls,
    ...rest
  };
  const ElementType = getSuitableElementType({
    showVideo,
    prefersReducedMotion,
    isInitialPage,
    index
  });

  return <ElementType {...props} />;
};

ProductHero.propTypes = {
  isInitialPage: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  showVideo: PropTypes.bool.isRequired,
  additionalImageUrls: PropTypes.arrayOf(PropTypes.string)
};

export default require("../../../containers/withTogglePoint").default(ProductHero, require.context("./__variants__/", true, /^\.\/([^/]+\/){2}index\.js$/))
