import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { withTranslation } from "../translation";
import { getSearchTerm } from "../../state/modules/search";
import { SEARCH_TERM_ID } from "../constants";
import styles from "./index.css";
import SuggestedTerm from "./SuggestedTerm";

export const SearchTerm = ({ formatTranslation }) => {
  const searchTerm = useSelector(getSearchTerm);

  if (!searchTerm) {
    return null;
  }

  return (
    <div id={SEARCH_TERM_ID} className={styles.wrapper}>
      <p className={styles.text}>
        {formatTranslation("plp_web_suggested_term_suggestion_title")}
      </p>
      <p className={styles.searchTerm}>
        {formatTranslation("plp_web_suggested_term_user_search_term", {
          searchTerm
        })}
      </p>
      <SuggestedTerm />
    </div>
  );
};

SearchTerm.propTypes = {
  formatTranslation: PropTypes.func.isRequired
};

export default withTranslation(SearchTerm);
