import AutoRetry from "./AutoRetry";
import { retrySearch } from "../../../state/modules/search/actions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const ConnectedAutoRetry = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrySearch());
  }, []);

  return <AutoRetry {...props} />;
};

export default ConnectedAutoRetry;
