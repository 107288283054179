import PropTypes from "prop-types";
import styles from "./index.css";
import { withTranslation } from "../../../../../../translation";

const NewPill = ({ formatTranslation }) => (
  <div className={styles.newPill}>
    <span>{formatTranslation("plp_onboarding_promo_label_new_pill")}</span>
  </div>
);

NewPill.propTypes = {
  formatTranslation: PropTypes.func.isRequired
};

export default withTranslation(NewPill);
