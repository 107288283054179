import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import media from "../../../../../customMedia.json";
import { useMatchMedia } from "../../../../../hooks";
import HoverableProductImage from "../../../../../HoverableProductImage";
import ProductImage from "../../../../../ProductImage";
import getSuitableElementType from "../getSuitableElementType";
import getOriginalSuitableElementType from "../../../getSuitableElementType";
import ImageGallery from "./ImageGallery";
import { getCategoryTreatments } from "../../../../../../state/modules/category";
import { getViewportCategory } from "../../../../../../state/modules/layout/selectors";
import { LARGE_VIEWPORT } from "../../../../../../state/modules/layout/constants";
import { getPlatform } from "../../../../../../state/modules/config/selectors";

const {
  customMedia: { ["--medium-viewport"]: mediumViewport }
} = media;

const getImageComponent = ({
  prefersReducedMotion,
  supportsHover,
  hasAdditionalImages,
  platform
}) => {
  if (!hasAdditionalImages) return ProductImage;
  if (supportsHover && prefersReducedMotion === false)
    return HoverableProductImage;
  if (platform !== "desktop") return ImageGallery;

  return ProductImage;
};

const ProductHero = ({
  isInitialPage,
  index,
  showVideo,
  additionalImageUrls,
  ...rest
}) => {
  const treatments = useSelector(getCategoryTreatments);
  const viewportCategory = useSelector(getViewportCategory);
  const platform = useSelector(getPlatform);
  let getCorrectSuitableElementType = getSuitableElementType;
  if (treatments && viewportCategory !== LARGE_VIEWPORT) {
    additionalImageUrls = null;
    getCorrectSuitableElementType = getOriginalSuitableElementType;
  }

  const isMediumViewportOrGreater = useMatchMedia(mediumViewport);
  const prefersReducedMotion = useMatchMedia("(prefers-reduced-motion)");
  const hasAdditionalImages = additionalImageUrls !== null;
  const supportsHover = useMatchMedia("(hover: hover)");
  const props = {
    ImageComponent: getImageComponent({
      prefersReducedMotion,
      supportsHover,
      hasAdditionalImages,
      platform
    }),
    isMediumViewportOrGreater,
    additionalImageUrls,
    isInitialPage,
    index,
    ...rest
  };
  const ElementType = getCorrectSuitableElementType({
    showVideo,
    prefersReducedMotion,
    isInitialPage,
    index
  });

  return <ElementType {...props} />;
};

ProductHero.propTypes = {
  isInitialPage: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  showVideo: PropTypes.bool.isRequired,
  additionalImageUrls: PropTypes.arrayOf(PropTypes.string)
};

export default ProductHero;
