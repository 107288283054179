import PropTypes from "prop-types";
import config from "../../config/common";
import Advert from "../Advert";

const withAdverts = WrappedComponent => {
  const { adverts: { adPosition } = {} } = config;

  const WithAdverts = ({ itemCount, ...rest }) => {
    const [listAdvert, footerAdvert] =
      adPosition === undefined || itemCount === 0
        ? [null, null]
        : itemCount > adPosition
        ? [Advert, null]
        : [null, Advert];

    return (
      <WrappedComponent
        {...{
          ...rest,
          itemCount,
          listAdvert,
          footerAdvert
        }}
      />
    );
  };

  WithAdverts.propTypes = {
    itemCount: PropTypes.number.isRequired
  };

  return WithAdverts;
};

export default withAdverts;
