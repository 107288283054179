import PropTypes from "prop-types";
import { connect } from "react-redux";
import { options } from "../queryParamsToOptions";
import Select from "../../Select";
import { bindActionCreators } from "redux";
import { withTranslation } from "../../translation";
import { getLocation } from "../../../state/modules/router/selectors";
import { changeSort } from "../../../state/modules/search/actions";
import { getPageName } from "../../../state/modules/page/selectors";
import styles from "./index.css";

export const SmallScreenSort = ({
  by,
  changeSort,
  formatTranslation,
  pageName
}) => {
  const selected = by || "";
  const handleOnSelect = (sortedBy, name) => {
    changeSort({ type: sortedBy || undefined, name });
  };

  return (
    <div className={styles.container}>
      <label htmlFor="sortBy" className={styles.label}>
        {formatTranslation("plp_web_sort_sort")}
      </label>
      <Select
        id="sortBy"
        className={styles.select}
        options={options(pageName)}
        selected={selected}
        onSelect={handleOnSelect}
      />
    </div>
  );
};

SmallScreenSort.displayName = "Sort";

SmallScreenSort.propTypes = {
  by: PropTypes.string,
  changeSort: PropTypes.func.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  pageName: PropTypes.string.isRequired
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    by: getLocation(state).query.sort,
    pageName: getPageName(state)
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      changeSort
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(SmallScreenSort));
