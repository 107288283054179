import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "../../../translation";
import { getStateForPriceSlider } from "../../../../state/modules/search";
import PriceSlider from "../../../PriceSlider";
import ClearButton from "../../../ToggleButton/ClearButton";
import styles from "./PriceRange.css";

export const PriceRange = ({
  facet,
  priceRange,
  clear,
  formatTranslation,
  formatCurrency
}) => {
  const { min, max } = priceRange.value;

  return (
    <div>
      <header className={styles.header}>
        <div className={styles.topLine}>
          <div className={styles.listSelections}>
            <p className={styles.priceRangeSelectedTitle}>
              {formatTranslation(
                "plp_web_refinements_list_price_range_selected_title"
              )}
            </p>
            <p className={styles.selectedRange}>
              {formatTranslation(
                "plp_web_refinements_list_price_range_selection",
                {
                  minValue: formatCurrency(min, true),
                  maxValue: formatCurrency(max, true)
                }
              )}
            </p>
          </div>
          <ClearButton
            className={styles.clearButton}
            facets={[facet]}
            onClick={() => clear(facet.id)}
          >
            {formatTranslation("plp_web_refinements_list_clear")}
          </ClearButton>
        </div>
      </header>
      <div className={styles.content}>
        <div className={styles.priceSliderWrapper}>
          <PriceSlider facet={facet} />
        </div>
      </div>
    </div>
  );
};

PriceRange.propTypes = {
  facet: PropTypes.object.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  formatCurrency: PropTypes.func.isRequired,
  priceRange: PropTypes.shape({
    value: PropTypes.shape({
      min: PropTypes.number.isRequired,
      max: PropTypes.number.isRequired
    })
  })
};

PriceRange.displayName = "PriceRange";

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    priceRange: getStateForPriceSlider(state)
  };
}

export default connect(mapStateToProps)(withTranslation(PriceRange));
