import PropTypes from "prop-types";
import styles from "./TextMultiSelect.css";
import { withTranslation } from "../../../translation";
import Label from "./Label";

const TextMultiSelect = ({
  facet,
  facetValue,
  changeMultiFacet,
  formatNumber
}) => {
  const uid = `${facet.id}_${facetValue.id}`;

  const changeHandler = () => {
    changeMultiFacet([
      {
        facet,
        facetValue,
        isSelected: !facetValue.isSelected
      }
    ]);
  };

  const keyDownHandler = event => {
    const isEnterKey = event.key && event.key === "Enter";

    if (isEnterKey) {
      return changeHandler();
    }
  };

  return (
    <div className={styles.textMultiSelect}>
      <input
        type="checkbox"
        className={styles.input}
        id={uid}
        checked={facetValue.isSelected}
        onChange={changeHandler}
        onKeyDown={keyDownHandler}
        value={facetValue.id}
      />
      <Label
        uid={uid}
        hasSwatches={facet.hasSwatches}
        swatchColour={facetValue.swatch}
        facetValueName={facetValue.name}
        classNames={styles.label}
        count={formatNumber(facetValue.count)}
        isSelected={facetValue.isSelected}
      />
    </div>
  );
};

TextMultiSelect.propTypes = {
  facet: PropTypes.object.isRequired,
  facetValue: PropTypes.object.isRequired,
  changeMultiFacet: PropTypes.func.isRequired,
  formatNumber: PropTypes.func.isRequired
};

export default withTranslation(TextMultiSelect);
