import PropTypes from "prop-types";
import LoadButton from "../Results/LoadButton";
import { decodeAndSortUrl } from "../../helpers/decodeAndSortUrl";

const LoadMoreButton = ({
  isLoadingMore,
  loadMore,
  nextPagePath,
  formatTranslation,
  formatLink
}) => (
  <LoadButton
    loadCallback={loadMore}
    isLoading={isLoadingMore}
    testId="loadMoreProducts"
    linkUrl={decodeAndSortUrl(formatLink(nextPagePath))}
    formatTranslation={formatTranslation}
  >
    {formatTranslation("plp_web_search_load_more_button_text")}
  </LoadButton>
);

LoadMoreButton.propTypes = {
  isLoadingMore: PropTypes.bool.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  formatLink: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
  nextPagePath: PropTypes.string.isRequired
};

export default LoadMoreButton;
