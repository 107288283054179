import { useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import getAriaLabelledBy from "../getAriaLabelledBy";
import saveBeaconsInStorage from "./SaveBeaconsInStorage";
import TileContext from "../../TileContext";
import {
  shouldTrackProductClick,
  shouldSendTreatmentAnalytics,
  updateHrefForAnalytics
} from "./shouldTrackProductClick";

const ProductLink = ({
  className,
  children,
  focusOnMount,
  anchorProductToUrl,
  href,
  analyticsProductClick,
  productTileId,
  carouselCatId,
  productId,
  onClickBeacon,
  advertId,
  viewportCategory
}) => {
  const labels = getAriaLabelledBy({ productId: productTileId });
  const anchorRef = useRef();
  const { tileSize } = useContext(TileContext);

  useEffect(() => {
    if (focusOnMount) {
      if (anchorRef.current) {
        anchorRef.current.focus();
      }
    }
  }, []);

  const sendTreatmentAnalytics = shouldSendTreatmentAnalytics({
    tileSize,
    viewportCategory
  });

  href = updateHrefForAnalytics({ href, sendTreatmentAnalytics });

  const onClick = () => {
    anchorProductToUrl({ productTileId, carouselCatId });

    if (
      shouldTrackProductClick({
        carouselCatId,
        advertId,
        sendTreatmentAnalytics
      })
    ) {
      analyticsProductClick({
        carouselCatId,
        advertId,
        sendTreatmentAnalytics
      });
    }
    saveBeaconsInStorage({
      productId,
      onClickBeacon
    });
  };

  return (
    <a
      ref={anchorRef}
      className={className}
      href={href}
      aria-labelledby={labels.labelledBy}
      onClick={onClick}
    >
      {children}
    </a>
  );
};

ProductLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  viewportCategory: PropTypes.string,
  productTileId: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  anchorProductToUrl: PropTypes.func.isRequired,
  analyticsProductClick: PropTypes.func.isRequired,
  focusOnMount: PropTypes.bool.isRequired,
  carouselCatId: PropTypes.number,
  onClickBeacon: PropTypes.string.isRequired,
  productId: PropTypes.number.isRequired,
  advertId: PropTypes.string
};

export default ProductLink;
