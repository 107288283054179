import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { analyticsSaveForLater } from "../../../state/modules/analytics/actions";
import {
  saveProduct,
  updateProduct,
  removeSavedProduct,
  removeFromSummary,
  isProductSaved,
  getIdForSavedProduct
} from "../../../state/modules/savedItems";
import { withTranslation } from "../../translation";
import { setError } from "../../../state/modules/error";
import SaveForLater from "./SaveForLater";
import commonConfig from "../../../config/common";

const ConnectedSaveForLater = props => {
  const { eventIconType } = commonConfig;
  const dispatch = useDispatch();

  return (
    <SaveForLater
      {...props}
      analyticsSaveForLater={args => dispatch(analyticsSaveForLater(args))}
      saveProductInState={args => dispatch(saveProduct(args))}
      updateSavedProductInState={args => dispatch(updateProduct(args))}
      removeSavedProduct={args => dispatch(removeSavedProduct(args))}
      removeFromSummary={args => dispatch(removeFromSummary(args))}
      setError={args => dispatch(setError(args))}
      isSaved={useSelector(state => isProductSaved(state, props.productId))}
      savedItemId={useSelector(state =>
        getIdForSavedProduct(state, props.productId)
      )}
      eventIconType={eventIconType}
    />
  );
};

ConnectedSaveForLater.propTypes = {
  productId: PropTypes.number.isRequired
};

export default withTranslation(ConnectedSaveForLater);
