import ProductTile from "../ProductTile";
import TileContext from "../TileContext";
import ProductMedia from "../ProductTile/ProductMedia";
import { TILE_SIZE_ONE_BY_ONE } from "../../helpers/constants";

const mapProductsToProductTiles = ({ products, focusTileIndex, ...rest }) => {
  const items = products.map(({ product }, index) => {
    const { tileSize = TILE_SIZE_ONE_BY_ONE, heroMedia } =
      product?.treatment ?? {};

    return (
      <TileContext.Provider
        value={{ tileSize, heroMedia }}
        key={product.tileId}
      >
        <ProductTile
          {...{
            ...product,
            ProductHeroComponent: ProductMedia,
            focusOnMount: index === focusTileIndex,
            index,
            ...rest
          }}
        />
      </TileContext.Provider>
    );
  });

  return items;
};

export default mapProductsToProductTiles;
