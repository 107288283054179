import useSlotSizeRefresh from "./useSlotSizeRefresh";
import useRefreshIfPriorSiblingsChanged from "./useRefreshIfPriorSiblingsChanged";
import useSlot from "./useSlot";
import useSlotTargeting from "./useSlotTargeting";

const useAdvert = ({
  id,
  sizeMappings,
  targeting,
  adUnitPath,
  validSlotSizes,
  priorSiblingsFingerprint
}) => {
  const slot = useSlot({ id, sizeMappings, adUnitPath, targeting });
  useSlotTargeting({ slot, targeting });
  useSlotSizeRefresh({ slot, validSlotSizes });
  useRefreshIfPriorSiblingsChanged({ slot, priorSiblingsFingerprint });
};
export default useAdvert;
