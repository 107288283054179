import { connect } from "react-redux";

import withTextFilter from "../../components/withTextFilter";
import { updateSearchTerm } from "../../state/modules/facet/actions";

const mapDispatchToProps = {
  onChange: updateSearchTerm
};

export default component =>
  connect(null, mapDispatchToProps)(withTextFilter(component));
