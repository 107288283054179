import { useState } from "react";
import PropTypes from "prop-types";
import styles from "./index.css";
import CloseButton from "../CloseButton";
import NewPill from "./NewPill";
import { withTranslation } from "../../../../../translation";

const ImageGalleryOnBoardingMessage = ({
  updateImageGalleryOnboardDismissed,
  imageGalleryOnboardDismissed,
  formatTranslation,
  platform,
  categoryTreatments
}) => {
  const [showMessage, setShowMessage] = useState(!imageGalleryOnboardDismissed);
  const curatedMobile = categoryTreatments;

  return showMessage && platform !== "desktop" && !curatedMobile ? (
    <div className={styles.imageGalleryOnboardingMessage}>
      <div>
        <NewPill formatTranslation={formatTranslation} />
        <div className={styles.informationWrapper}>
          <div className={styles.icon} aria-hidden={true} />
          <div className={styles.messageWrapper}>
            <p className={styles.title}>
              {formatTranslation("plp_onboarding_image_galleries_title")}
            </p>
            <p className={styles.text}>
              {formatTranslation("plp_onboarding_image_galleries_subtitle")} 👇
            </p>
          </div>
        </div>
      </div>
      <CloseButton
        onClick={() => {
          setShowMessage(false);
          updateImageGalleryOnboardDismissed(true);
        }}
      />
    </div>
  ) : null;
};

ImageGalleryOnBoardingMessage.propTypes = {
  updateImageGalleryOnboardDismissed: PropTypes.func,
  imageGalleryOnboardDismissed: PropTypes.bool,
  formatTranslation: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired,
  viewportCategory: PropTypes.string,
  categoryTreatments: PropTypes.object
};

export default withTranslation(ImageGalleryOnBoardingMessage);
