import PropTypes from "prop-types";
import LoadMoreButton from "../LoadMore";
import ProgressBar from "../ProgressBar";

const ResultsFooter = ({
  hasPages,
  itemCount,
  offset: { upper },
  showLoadMore
}) => (
  <>
    {hasPages ? <ProgressBar max={itemCount} value={upper} /> : null}
    {showLoadMore && <LoadMoreButton />}
  </>
);

ResultsFooter.propTypes = {
  hasPages: PropTypes.bool.isRequired,
  itemCount: PropTypes.number.isRequired,
  offset: PropTypes.shape({
    upper: PropTypes.number.isRequired
  }).isRequired,
  showLoadMore: PropTypes.bool.isRequired
};

export default ResultsFooter;
