import PropTypes from "prop-types";
import StyleCount from "../StyleCount";
import LoadPreviousButton from "../LoadPrevious";
import RankingDisclaimer from "../RankingDisclaimer";

const ResultsHeader = ({ showLoadPrevious, itemCount }) =>
  showLoadPrevious ? (
    <>
      <LoadPreviousButton />
      <RankingDisclaimer />
    </>
  ) : (
    <>
      <StyleCount itemCount={itemCount} />
      <RankingDisclaimer />
    </>
  );

ResultsHeader.propTypes = {
  itemCount: PropTypes.number.isRequired,
  showLoadPrevious: PropTypes.bool.isRequired
};

export default require("../../containers/withTogglePoint").default(ResultsHeader, require.context("./__variants__/", true, /^\.\/([^/]+\/){2}ResultsHeader\.js$/))
