import getDiscount from "./getDiscount";

const getDealDetails = ({
  price,
  reducedPrice,
  formatCurrency,
  formatTranslation,
  dealBannerEnabled,
  euOmnibusEnabled
}) => {
  let formattedReducedPrice = null,
    deal = null,
    dealAria = null;

  if (reducedPrice) {
    formattedReducedPrice = formatCurrency(reducedPrice);

    if (dealBannerEnabled) {
      if (!euOmnibusEnabled) {
        deal = `-${getDiscount({ oldPrice: price, newPrice: reducedPrice })}%`;
        dealAria = `${formatTranslation(
          "generic_accessibility_discount"
        )}: ${deal}`;
      } else {
        deal = formatTranslation("deal_label");
        dealAria = deal;
      }
    }
  }

  return {
    formattedPrice: formatCurrency(price),
    formattedReducedPrice,
    deal,
    dealAria
  };
};

export default getDealDetails;
