export const getFacetWithSwatches = ({ facet, swatchesMap }) => {
  const hasSwatches = facet.facetValues.some(
    ({ id: facetId }) => swatchesMap[facetId]
  );

  if (!hasSwatches) {
    return facet;
  }

  const facetValuesWithSwatches = facet.facetValues.map(facetValue => {
    const swatch = swatchesMap[facetValue.id];

    if (!swatch) {
      return facetValue;
    }

    return { ...facetValue, swatch };
  });

  return {
    ...facet,
    facetValues: facetValuesWithSwatches,
    hasSwatches
  };
};
