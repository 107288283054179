import PropTypes from "prop-types";

const SelectAllButton = props => {
  const {
    openFacet: { hasSelectedValues, facetType },
    className,
    onClick,
    children
  } = props;

  return hasSelectedValues || facetType === "Range" ? null : (
    <button data-auto-id="all" className={className} onClick={onClick}>
      {children}
    </button>
  );
};

SelectAllButton.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  openFacet: PropTypes.object.isRequired
};

export default SelectAllButton;
