import { useSelector } from "react-redux";
import {
  getPages,
  getOffset,
  getIsNewPage,
  getIsLoading,
  getIsLoadingPrevious,
  getShowLoadMore,
  getShowLoadPrevious,
  getTileToFocus
} from "../../state/modules/search/selectors";
import { isErrorOfTypeServer as getIsErrorOfTypeServer } from "../../state/modules/error";
import { Results } from "./Results";

const ConnectedResults = props => {
  const pages = useSelector(getPages);
  const offset = useSelector(getOffset);
  const isErrorOfTypeServer = useSelector(getIsErrorOfTypeServer);
  const isNewPage = useSelector(getIsNewPage);
  const isLoading = useSelector(getIsLoading);
  const isLoadingPrevious = useSelector(getIsLoadingPrevious);
  const showLoadMore = useSelector(getShowLoadMore);
  const showLoadPrevious = useSelector(getShowLoadPrevious);
  const tileToFocus = useSelector(getTileToFocus);

  return (
    <Results
      {...{
        ...props,
        pages,
        isErrorOfTypeServer,
        isLoading,
        isNewPage,
        isLoadingPrevious,
        showLoadMore,
        showLoadPrevious,
        tileToFocus,
        offset
      }}
    />
  );
};

export default require("../../containers/withTogglePoint").default(ConnectedResults, require.context("./__variants__/", true, /^\.\/([^/]+\/){2}index\.js$/))
