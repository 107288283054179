import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import Headroom from "react-headroom";
import noTouchAction from "../../helpers/noTouchAction";
import FacetValues from "./FacetValues";
import DropdownMenu from "./DropdownMenu";
import LargeScreenSort from "../Sort/LargeScreenSort";
import Escapable from "../Escapable";
import TouchOutside from "../TouchOutside";
import WindowEventsListener from "../WindowEventsListener";
import style from "./HorizontalRefinements.css";
import { withTranslation } from "../translation";
import { COLOUR_FACET_ID } from "../../state/modules/facet/constants";
import { getFacetWithSwatches } from "../../client/getFacetWithSwatches";
import {
  getVerticalDisplacement,
  getPinStart
} from "./getVerticalDisplacement";

const combineClasses = classNames.bind(style);
const SORT_ID = "sort";
const styleContext = classNames.bind(style);

export const HorizontalRefinements = ({
  facets,
  getColourEnrichments,
  newFacets,
  selectAll,
  clear,
  query,
  formatTranslation,
  changeMultiFacet,
  hasFloor,
  analyticsOpenRefinementType,
  analyticsCloseRefinementType,
  swatchesMap,
  colourFilterPresent
}) => {
  const [verticalDisplacement, setVerticalDisplacement] = useState();
  const [openFacet, setOpenFacet] = useState({});
  const facetRef = useRef(null);

  useEffect(() => {
    if (colourFilterPresent) {
      getColourEnrichments();
    }
  }, [colourFilterPresent]);

  const getEnrichedFacet = facet => {
    if (facet.id !== COLOUR_FACET_ID) {
      return facet;
    }

    return getFacetWithSwatches({ facet, swatchesMap });
  };

  const updateDisplacement = () => {
    const element = getCurrentDropdownElement();

    if (!element) {
      return;
    }

    setVerticalDisplacement({
      verticalDisplacement: getVerticalDisplacement(element)
    });
  };

  const getCurrentDropdownElement = openFacetId =>
    facetRef.current.querySelector(
      `[data-auto-id=${openFacetId || openFacet.id}]`
    );

  const toggleOpenFacet = (facetId, facetName) => {
    const { id: currentOpenId, name: currentOpenName } = openFacet;
    const facetToOpenId = facetId !== currentOpenId ? facetId : null;
    const facetToOpenName = facetName !== currentOpenName ? facetName : null;

    facetToOpenId
      ? analyticsOpenRefinementType({ id: facetId, name: facetName })
      : currentOpenId &&
        analyticsCloseRefinementType({
          id: currentOpenId,
          name: currentOpenName
        });

    noTouchAction(!!facetToOpenId);
    setOpenFacet({ id: facetToOpenId, name: facetToOpenName });
    setVerticalDisplacement(
      getVerticalDisplacement(getCurrentDropdownElement(facetToOpenId))
    );
  };

  const focusToggleAndClose = () => {
    focusToggle();
    toggleOpenFacet();
  };

  const focusToggle = () => {
    const element = facetRef.current.querySelector(
      `[data-auto-id="${openFacet.id}"] [data-filter-toggle]`
    );

    if (element) {
      element.focus();
    }
  };

  return (
    <Headroom
      className={combineClasses({
        stickyHeader: true,
        stickyHeader_hasFloor: hasFloor
      })}
      disableInlineStyles={true}
      onUnpin={focusToggleAndClose}
      pinStart={getPinStart}
      upTolerance={17}
    >
      <div
        role="region"
        aria-label={formatTranslation("generic_accessibility_filters")}
        className={style.horizontalRefinements}
      >
        <ul ref={facetRef} className={style.refinementsList}>
          <Escapable escapeHandler={focusToggleAndClose} />
          <TouchOutside
            getElement={getCurrentDropdownElement}
            outsideHandler={toggleOpenFacet}
          />
          <WindowEventsListener
            events={["resize"]}
            handler={updateDisplacement}
            toDebounce={true}
          />
          <li className={style.li} data-auto-id={SORT_ID}>
            <DropdownMenu
              id={SORT_ID}
              name={formatTranslation("plp_web_sort_sort")}
              click={toggleOpenFacet}
              isOpen={openFacet.id === SORT_ID}
              hasSelectedValues={Boolean(query.sort)}
              isNew={false}
              haspopup={"listbox"}
            >
              {openFacet.id === SORT_ID && (
                <LargeScreenSort close={focusToggleAndClose} />
              )}
            </DropdownMenu>
          </li>
          {facets.map(facet => (
            <li data-auto-id={facet.id} className={style.li} key={facet.name}>
              <DropdownMenu
                id={facet.id}
                name={facet.name}
                click={toggleOpenFacet}
                isOpen={openFacet.id === facet.id}
                hasSelectedValues={facet.hasSelectedValues}
                isNew={newFacets.includes(facet.id)}
                haspopup={true}
              >
                {openFacet.id === facet.id && (
                  <FacetValues
                    facet={getEnrichedFacet(facet)}
                    selectAll={selectAll}
                    clear={clear}
                    changeMultiFacet={changeMultiFacet}
                    verticalDisplacement={verticalDisplacement}
                  />
                )}
              </DropdownMenu>
            </li>
          ))}
        </ul>
      </div>
      <div
        className={styleContext({
          blockInteract: true,
          blockInteract__visible: !!openFacet.id
        })}
      />
    </Headroom>
  );
};

HorizontalRefinements.propTypes = {
  hasFloor: PropTypes.bool.isRequired,
  facets: PropTypes.array.isRequired,
  newFacets: PropTypes.array.isRequired,
  selectAll: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  changeMultiFacet: PropTypes.func.isRequired,
  analyticsOpenRefinementType: PropTypes.func.isRequired,
  analyticsCloseRefinementType: PropTypes.func.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  swatchesMap: PropTypes.object.isRequired,
  getColourEnrichments: PropTypes.func.isRequired,
  colourFilterPresent: PropTypes.bool.isRequired
};

export default withTranslation(HorizontalRefinements);
