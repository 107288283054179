import PropTypes from "prop-types";
import UpSellCarousel from ".";
import { MINIMUM_SEARCH_RESULTS_FOR_CAROUSEL } from "./constants";

const withUpSellCarousel = WrappedComponent => {
  const WithUpSellCarousel = ({ itemCount, carouselProps, ...rest }) => {
    let upSellCarousel = null;
    if (
      carouselProps !== null &&
      itemCount >= MINIMUM_SEARCH_RESULTS_FOR_CAROUSEL
    ) {
      upSellCarousel = <UpSellCarousel {...carouselProps} />;
    }

    return (
      <WrappedComponent
        {...{
          ...rest,
          itemCount,
          upSellCarousel
        }}
      />
    );
  };

  WithUpSellCarousel.propTypes = {
    itemCount: PropTypes.number.isRequired,
    carouselProps: PropTypes.object
  };

  return WithUpSellCarousel;
};

export default withUpSellCarousel;
