const getPriceInfo = ({
  price,
  reducedPrice,
  isOutlet,
  hasMultiplePrices,
  formatTranslation,
  euOmnibusEnabled
}) => {
  const fromLabel = hasMultiplePrices
    ? `${formatTranslation("plp_web_product_tile_price_from")} `
    : "";
  const priceLabel = formatTranslation("plp_web_a11y_price");
  const currentPriceLabel = formatTranslation("generic_current_price");

  if (euOmnibusEnabled) {
    return `${fromLabel}; ${currentPriceLabel}: ${
      reducedPrice ? reducedPrice : price
    }`;
  }

  if (reducedPrice) {
    const previousPriceLabel = isOutlet
      ? formatTranslation("generic_price_rrp")
      : formatTranslation("plp_web_a11y_original_price");

    return `${previousPriceLabel}: ${price}, ${currentPriceLabel}: ${fromLabel}${reducedPrice}`;
  }

  return `${priceLabel}: ${fromLabel}${price}`;
};

export default getPriceInfo;
