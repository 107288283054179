import PropTypes from "prop-types";
import styles from "./index.css";

const ProductDeal = ({ deal, dealAria, labelledById }) => (
  <span
    id={labelledById}
    aria-hidden={true}
    aria-label={dealAria}
    className={styles.productDeal}
  >
    {`(${deal})`}
  </span>
);

ProductDeal.propTypes = {
  deal: PropTypes.string.isRequired,
  dealAria: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelledById: PropTypes.string.isRequired
};

export default ProductDeal;
