import { PRODUCT_BEACONS_MAP_KEY } from "./constants";

const getProductBeaconsMapFromStorage = () => {
  let productBeaconsMap;
  try {
    productBeaconsMap =
      JSON.parse(sessionStorage.getItem(PRODUCT_BEACONS_MAP_KEY)) || {};
  } catch (_) {
    productBeaconsMap = {};
  }

  return productBeaconsMap;
};

export default getProductBeaconsMapFromStorage;
