import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { PLACEMENT } from "@asosteam/asos-web-adverts-sdk";
import DisplayAdvert from "@asosteam/asos-web-adverts-display";
import getAdvertsSdk from "../../client/adverts/getAdvertsSdk";

const CriteoAds = ({
  app,
  requestSponsoredProducts,
  parameters,
  className
}) => {
  const [advertsSdk, setAdvertSdk] = useState();

  useEffect(() => {
    const getSdk = async () => {
      const sdk = await getAdvertsSdk();
      setAdvertSdk(sdk);
    };

    getSdk();
  }, []);

  if (advertsSdk && (parameters.keywords || parameters.category)) {
    return (
      <div className={className}>
        <DisplayAdvert
          sdkInstance={advertsSdk}
          app={app}
          placement={PLACEMENT.MIDDLE}
          requestDisplayAdverts={true}
          requestSponsoredProducts={requestSponsoredProducts}
          parameters={parameters}
          rootMargin="500px"
        />
      </div>
    );
  } else {
    return null;
  }
};

CriteoAds.propTypes = {
  app: PropTypes.string.isRequired,
  requestSponsoredProducts: PropTypes.bool.isRequired,
  parameters: PropTypes.shape({
    "list-size": PropTypes.number.isRequired,
    "page-number": PropTypes.number.isRequired,
    filters: PropTypes.array.isRequired,
    keywords: PropTypes.string,
    category: PropTypes.string,
    "item-whitelist": PropTypes.string
  }).isRequired,
  className: PropTypes.string
};

export default CriteoAds;
