import { LARGE_VIEWPORT } from "../../../state/modules/layout/constants";
import { TILE_SIZES } from "../../../helpers/constants";
import productTileStyles from "../ProductTile.css";
import uponTile from "./uponTileStyle/index.css";
import underneathTile from "./underneathTileStyle/index.css";
import badgesFactory from "./badgesFactory";

const {
  bottomLeftBadge,
  bottomRightBadge,
  underneathBadge
} = productTileStyles;

const badges = ({ viewportCategory, tileSize }) => {
  const isFeaturedProduct = tileSize !== TILE_SIZES.TILE_SIZE_ONE_BY_ONE;

  if (isFeaturedProduct) {
    return {};
  }

  const restockingSoonClasses = [
    underneathTile.badge,
    underneathTile.restockingSoon,
    underneathBadge
  ];

  if (viewportCategory !== LARGE_VIEWPORT) {
    return {
      uponTileBadges: badgesFactory({
        productClasses: [uponTile.productBadge, bottomLeftBadge],
        sellingFastClasses: [uponTile.sellingFastBadge, bottomRightBadge]
      }),
      underneathTileBadges: badgesFactory({
        restockingSoonClasses
      })
    };
  }

  return {
    underneathTileBadges: badgesFactory({
      restockingSoonClasses,
      productClasses: [
        underneathTile.badge,
        underneathTile.productBadge,
        underneathBadge
      ],
      sellingFastClasses: [
        underneathTile.badge,
        underneathTile.sellingFastBadge,
        underneathBadge
      ]
    })
  };
};

export default badges;
