import { Component } from "react";
import PropTypes from "prop-types";
import debounce from "../../helpers/debounce";

const toggleEvents = (toggle, { target, events, handler }) => {
  const toggleEvent = (toggle
    ? target.addEventListener
    : target.removeEventListener
  ).bind(target);

  events.forEach(event => toggleEvent(event, handler));
};

class WindowEventsListener extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    if (this.props.toDebounce) {
      this.state.handler = debounce(this.props.handler, 200);
    }
  }

  componentDidMount() {
    toggleEvents(true, { target: window, ...this.props, ...this.state });
  }

  componentWillUnmount() {
    this.state.handler && this.state.handler.clear();
    toggleEvents(false, { target: window, ...this.props, ...this.state });
  }

  render() {
    return null;
  }
}

WindowEventsListener.propTypes = {
  events: PropTypes.arrayOf(PropTypes.string.isRequired),
  handler: PropTypes.func.isRequired,
  toDebounce: PropTypes.bool.isRequired
};

export default WindowEventsListener;
