const getPositionInItemsArrayFromPositionInProductTilesArray = productsTiles => ({
  positionInProductTilesArray,
  items
}) => {
  positionInProductTilesArray = Math.min(
    positionInProductTilesArray,
    productsTiles.length
  );

  const preceedingProductTile = productsTiles[positionInProductTilesArray - 1];

  const preceedingProductTileIndexInItemsArray = items.indexOf(
    preceedingProductTile
  );

  return preceedingProductTileIndexInItemsArray + 1;
};

export default getPositionInItemsArrayFromPositionInProductTilesArray;
