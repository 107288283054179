import { useRef, useEffect } from "react";
import setupSlot from "./setupSlot";
import destroySlot from "./destroySlot";
import getSlot from "./getSlot";
import refreshSlot from "../refreshSlot";

const useSlot = ({ id, sizeMappings, adUnitPath, targeting }) => {
  const slotRef = useRef(getSlot({ id }));
  const { current: slot } = slotRef;

  useEffect(() => {
    if (!slot) {
      setupSlot({
        id,
        sizeMappings,
        adUnitPath,
        targeting
      }).then(slot => (slotRef.current = slot));
    } else {
      refreshSlot({ slot });
    }

    return () => {
      const { current: slot } = slotRef;
      if (slot) {
        destroySlot({ slot });
      }
    };
  }, []);

  return slot;
};

export default useSlot;
