import ProductVideo from "../../ProductVideo";
import PropTypes from "prop-types";
import { useState } from "react";

const VideoWithFallback = ({ ImageComponent, ...rest }) => {
  const [showFallback, setShowFallback] = useState(false);

  return showFallback ? (
    <ImageComponent {...rest} />
  ) : (
    <ProductVideo
      {...{
        onError: () => setShowFallback(true),
        ImageComponent,
        ...rest
      }}
    />
  );
};

VideoWithFallback.propTypes = {
  ImageComponent: PropTypes.elementType.isRequired
};

export default VideoWithFallback;
