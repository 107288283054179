import PropTypes from "prop-types";
import classnames from "classnames";
import PrimaryListFacetItem from "./PrimaryListFacetItem";
import ClearButton from "../../ToggleButton/ClearButton";
import { isFacetPriceRange } from "../../../state/modules/search/priceRangeSelectors";

const PrimaryList = props => {
  const { formatCurrency, formatTranslation, styles, range, newFacets } = props;

  const shouldRenderFacet = (facet, range) =>
    isFacetPriceRange(facet) && range.minValue === range.maxValue
      ? false
      : true;

  const handleViewItemsClick = () => {
    props.viewList();
  };

  return (
    <div className={styles.primaryList}>
      <header className={styles.refinementsHeader}>
        <div className={styles.topLine}>
          <h3 className={styles.refinementsTitle}>
            {formatTranslation("plp_web_refinements_list_filter")}
          </h3>
          <ClearButton
            className={styles.clearBtn}
            onClick={props.clearAll}
            facets={props.facets}
          >
            {formatTranslation("plp_web_refinements_list_clear_all")}
          </ClearButton>
        </div>
      </header>
      <div
        className={classnames(
          styles.refinementsListWrapper,
          styles.unfilterableRefinementsListWrapper
        )}
      >
        <ul className={styles.refinementsList}>
          {props.facets.map(facet =>
            shouldRenderFacet(facet, range) ? (
              <PrimaryListFacetItem
                key={facet.id}
                facet={facet}
                open={props.open}
                styles={styles}
                range={range}
                formatCurrency={formatCurrency}
                isNew={newFacets.includes(facet.id)}
              />
            ) : null
          )}
        </ul>
      </div>
      <footer className={styles.footer}>
        <button className={styles.viewItemsBtn} onClick={handleViewItemsClick}>
          {formatTranslation("plp_web_refinements_list_view_items", {
            smart_count: props.itemCount
          })}
        </button>
      </footer>
    </div>
  );
};

PrimaryList.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  formatCurrency: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired,
  range: PropTypes.object.isRequired,
  facets: PropTypes.arrayOf(PropTypes.object).isRequired,
  newFacets: PropTypes.array.isRequired,
  clearAll: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  viewList: PropTypes.func.isRequired,
  itemCount: PropTypes.number.isRequired
};

export default PrimaryList;
