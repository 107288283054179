import commonConfig from "../../../config/common";

const getFacetApiPath = () => {
  const pathSuffix = commonConfig.api.facet.path;

  if (SERVER) {
    return `${process.env.FACET_API_HOSTNAME}${pathSuffix}`;
  }

  return `/api${pathSuffix}`;
};

export default getFacetApiPath;
