import {
  HEADER_HEIGHT_WITHOUT_SEARCH,
  HEADER_HEIGHT_WITH_SEARCH
} from "./constants";

const getMaxHeight = ({ verticalDisplacement, searchBox }) =>
  CLIENT && verticalDisplacement > 0
    ? Math.round(
        window.innerHeight -
          verticalDisplacement -
          (searchBox !== null
            ? HEADER_HEIGHT_WITH_SEARCH
            : HEADER_HEIGHT_WITHOUT_SEARCH)
      )
    : 0;

export default getMaxHeight;
