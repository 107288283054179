import PropTypes from "prop-types";
import styles from "./UpSellCarouselSkeleton.css";
import classnames from "classnames";
import { MAX_PRODUCT_TILES } from "./constants";

const UpSellCarouselSkeleton = ({ isFrozen }) => (
  <div
    className={classnames(styles.container, {
      [styles.frozen]: isFrozen
    })}
  >
    <div className={styles.header}>
      <div className={styles.title}>
        <div className={classnames(styles.placeholder, styles.titleLineOne)} />
        <div className={classnames(styles.placeholder, styles.titleLineTwo)} />
      </div>
      <div className={classnames(styles.placeholder, styles.itemCounter)}></div>
    </div>
    <ul className={styles.productList}>
      {new Array(MAX_PRODUCT_TILES).fill().map((_, index) => (
        <li key={index} className={styles.productTile}>
          <div
            className={classnames(styles.placeholder, styles.productImage)}
          />
          <div
            className={classnames(styles.placeholder, styles.productLineOne)}
          />
          <div
            className={classnames(styles.placeholder, styles.productLineTwo)}
          />
          <div
            className={classnames(styles.placeholder, styles.productLineThree)}
          />
        </li>
      ))}
    </ul>
  </div>
);

UpSellCarouselSkeleton.propTypes = {
  isFrozen: PropTypes.bool
};

export default UpSellCarouselSkeleton;
