import { createAction } from "redux-actions";
import {
  RECEIVE_COLOUR_ENRICHMENT,
  COLOUR_FACET_ID,
  COLOUR_ENRICHMENT_LANG,
  COLOUR_ENRICHMENT_CHANNEL
} from "../constants";
import logger from "../../../../lib/logger";
import { getFacetData } from "../../../../lib/api/facet";

const getColourEnrichments = () => async dispatch => {
  try {
    const colourEnrichments = await getFacetData({
      facetId: COLOUR_FACET_ID,
      lang: COLOUR_ENRICHMENT_LANG,
      channel: COLOUR_ENRICHMENT_CHANNEL
    });

    const receiveColourEnrichment = createAction(RECEIVE_COLOUR_ENRICHMENT);
    dispatch(receiveColourEnrichment(colourEnrichments));
  } catch (error) {
    error.message = `getColourEnrichments failed, and caught error: ${error.message}`;
    logger.error(error);
  }
};

export default getColourEnrichments;
