import { getConfiguredRequest } from "../../../lib/request";

const webRequest = getConfiguredRequest();

const getBitrate = ({ bitrate }) => parseInt(bitrate);

const videoUrlProvider = async ({ rawManifestUrl, useHighBitrate }) => {
  const manifestUrl = `https:${rawManifestUrl}?req=set,json`;
  const { data } = await webRequest.get({
    url: manifestUrl,
    isThirdParty: true
  });
  const [, jsonString] = data.match(/s7jsonResponse\((.*),/);
  const {
    set: { item }
  } = JSON.parse(jsonString);
  const videos = item.map(({ v }) => v);
  const sorted = videos.sort((a, b) => getBitrate(a) - getBitrate(b));
  const { path } = useHighBitrate ? sorted.pop() : sorted[0];

  return `//video.asos-media.com/products/${path}`;
};

export default videoUrlProvider;
