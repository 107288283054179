import { useEffect, useState } from "react";

const usePlayWhenInView = ({
  isMediumViewportOrGreater,
  videoElement,
  videoSrc,
  onError
}) => {
  const [hasFirstPlayed, setHasFirstPlayed] = useState(false);

  useEffect(() => {
    let observer;

    const disconnect = () => observer?.disconnect();
    disconnect();

    if (videoElement && videoSrc) {
      let playPromise;
      observer = new IntersectionObserver(
        async entries => {
          if (entries.some(({ isIntersecting }) => isIntersecting)) {
            playPromise = videoElement.play();
            try {
              await playPromise;
            } catch (e) {
              return onError(e);
            }
            setHasFirstPlayed(true);
          } else {
            try {
              await playPromise;
            } catch (_) {
              return;
            }
            videoElement.pause();
          }
        },
        { rootMargin: isMediumViewportOrGreater ? "30px" : "50px" }
      );

      observer.observe(videoElement);
    }

    return disconnect;
  }, [isMediumViewportOrGreater, videoSrc, videoElement]);

  return hasFirstPlayed;
};

export default usePlayWhenInView;
