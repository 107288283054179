import {
  TARGETING_AND_ADVERTISING_COOKIES_ID,
  hasConsent
} from "@asosteam/asos-web-consent";

export default () => {
  const targetingAndAdvertisingAllowed = hasConsent([
    TARGETING_AND_ADVERTISING_COOKIES_ID
  ]);
  const nonPersonalizedAds = targetingAndAdvertisingAllowed ? 0 : 1;

  window.googletag.pubads().setRequestNonPersonalizedAds(nonPersonalizedAds);
};
