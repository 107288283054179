import PropTypes from "prop-types";
import TextMultiSelect from "../TextMultiSelect";
import styles from "./FacetValueGroup.css";

const FacetValueGroup = ({
  title,
  facet,
  groupedFacetValues,
  changeMultiFacet
}) => (
  <dl key={title} className={`${styles.container}`}>
    <dt className={styles.letterGroup}>{title}</dt>
    {groupedFacetValues.map((facetValue, index) => (
      <dd key={`${facetValue.id}-${index}`} className={styles.letterChild}>
        <TextMultiSelect
          facetValue={facetValue}
          key={facetValue.id}
          facet={facet}
          changeMultiFacet={changeMultiFacet}
        />
      </dd>
    ))}
  </dl>
);

FacetValueGroup.propTypes = {
  title: PropTypes.string.isRequired,
  facet: PropTypes.object.isRequired,
  changeMultiFacet: PropTypes.func.isRequired,
  groupedFacetValues: PropTypes.array.isRequired
};

export default FacetValueGroup;
