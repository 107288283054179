import classnames from "classnames/bind";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./index.css";
import { hideFilterOverlay } from "../../state/modules/layout/actions";
import { getFilterOverlayVisible } from "../../state/modules/layout/selectors";
import { analyticsRefinementsCloseMobile } from "../../state/modules/analytics/actions";
import TrapFocus from "../../components/TrapFocus";
import Escapable from "../../components/Escapable";

const styleContext = classnames.bind(styles);

export const FilterLayout = ({
  isFilterOverlayVisible,
  hideFilterOverlay,
  analyticsRefinementsCloseMobile,
  children
}) => {
  const layout = styleContext({
    filterLayout: true,
    showOnMobile: isFilterOverlayVisible
  });

  const handleClick = () => {
    if (isFilterOverlayVisible) {
      hideFilterOverlay();
      analyticsRefinementsCloseMobile();
    }
  };

  return (
    <div className={styles.container}>
      <Escapable escapeHandler={handleClick} />
      <div className={layout}>
        <aside
          className={styles.grayTapArea}
          onClick={handleClick}
          aria-hidden={true}
        />
        <TrapFocus
          className={styles.filterContainer}
          trapFocus={isFilterOverlayVisible}
        >
          {children}
        </TrapFocus>
      </div>
    </div>
  );
};

FilterLayout.propTypes = {
  children: PropTypes.node.isRequired,
  hideFilterOverlay: PropTypes.func.isRequired,
  analyticsRefinementsCloseMobile: PropTypes.func.isRequired,
  isFilterOverlayVisible: PropTypes.bool.isRequired
};

FilterLayout.displayName = "FilterLayout";

/* istanbul ignore next */
const mapDispatchToProps = {
  hideFilterOverlay,
  analyticsRefinementsCloseMobile
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    isFilterOverlayVisible: getFilterOverlayVisible(state)
  };
}

const FilterLayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterLayout);

export default FilterLayoutContainer;
