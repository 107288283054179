import getFacetApiUrl from "./getFacetApiUrl";
import config from "../../../config/common";
import { request } from "../";
import { FACET_REQUEST } from "../constants";

const {
  api: {
    facet: { timeout }
  }
} = config;

export const getFacetData = ({ facetId, lang, channel }) => {
  const options = { timeout };

  return request(
    getFacetApiUrl({ facetId, lang, channel }),
    options,
    FACET_REQUEST
  );
};
