import PropTypes from "prop-types";
import { useState, useRef } from "react";
import ProductImage from "../../../../../../ProductImage";
import LazyProductHeroContainer from "../../../../../../LazyProductHeroContainer";
import GalleryIndicator from "./GalleryIndicators";
import styles from "./index.css";
import useImageInView from "../../useImageInView";

const ImageGallery = ({
  additionalImageUrls,
  className,
  image,
  onLoad,
  onTransitionEnd
}) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [loadOnTouchImages, setLoadOnTouchImages] = useState(false);

  const galleryRef = useRef(null);

  const [currentImageIndex] = useImageInView({
    galleryRef,
    loadOnTouchImages,
    image
  });

  return (
    <>
      <div
        ref={galleryRef}
        className={styles.imageGallery}
        onTouchStart={() => {
          setLoadOnTouchImages(true);
          setShowPlaceholder(false);
        }}
      >
        <ProductImage
          {...{
            image,
            className,
            onLoad,
            onTransitionEnd
          }}
        />
        {showPlaceholder && <div />}
        {loadOnTouchImages &&
          additionalImageUrls.map(image => (
            <LazyProductHeroContainer
              key={image}
              doLoad={true}
              render={lazyProps => (
                <ProductImage
                  {...{
                    image,
                    ...lazyProps
                  }}
                />
              )}
            />
          ))}
      </div>
      <div className={styles.container}>
        {[...new Array(additionalImageUrls.length + 1)].map((_, indicator) => (
          <GalleryIndicator
            key={`galleryIndicator-${indicator}`}
            inView={indicator === currentImageIndex}
          />
        ))}
      </div>
    </>
  );
};

ImageGallery.propTypes = {
  additionalImageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
  onTransitionEnd: PropTypes.func
};

export default ImageGallery;
