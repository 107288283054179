import { ERROR_TYPE } from "../../../state/modules/error";

const getTranslationKeys = type => {
  switch (type) {
    case ERROR_TYPE.network:
      return {
        header: "plp_web_errors_no_connection_header",
        details: "plp_web_errors_no_connection_details"
      };
    case ERROR_TYPE.server:
      return {
        header: "plp_web_errors_generic_error_header",
        details: "plp_web_errors_generic_error_details"
      };
    default:
      return {
        header: "plp_web_errors_oops",
        details: "plp_web_errors_cannot_show_anything_on_page"
      };
  }
};

export default getTranslationKeys;
