import PropTypes from "prop-types";
import classnames from "classnames/bind";
import styles from "./Results.css";
import {
  ProgressIndicator,
  SIZE_MEDIUM
} from "@asosteam/asos-web-component-library-progress-indicator";

const combineClasses = classnames.bind(styles);

const handleCallback = callback => event => {
  event.preventDefault();
  callback();
};

export default function LoadButton({
  loadCallback,
  isLoading,
  linkUrl,
  testId,
  children,
  additionalClasses,
  formatTranslation
}) {
  const props = isLoading
    ? {
        onClick: handleCallback(() => {}),
        className: combineClasses(
          styles.loadButtonSpinnerWrapper,
          additionalClasses
        ),
        "aria-label": formatTranslation(
          "generic_accessibility_loading_products"
        )
      }
    : {
        onClick: handleCallback(loadCallback),
        className: combineClasses(styles.loadButton, additionalClasses),
        "data-auto-id": testId
      };

  const contents = isLoading ? (
    <ProgressIndicator size={SIZE_MEDIUM} />
  ) : (
    children
  );

  return (
    <a href={linkUrl} {...props}>
      {contents}
    </a>
  );
}

LoadButton.propTypes = {
  loadCallback: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  testId: PropTypes.string,
  linkUrl: PropTypes.string.isRequired,
  children: PropTypes.node,
  additionalClasses: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object
  ]),
  formatTranslation: PropTypes.func.isRequired
};
