import PropTypes from "prop-types";
import styles from "./index.css";
import classnames from "classnames";

export const GalleryIndicator = ({ inView }) => (
  <div
    className={classnames(styles.galleryIndicator, {
      [styles.inView]: inView
    })}
  ></div>
);

GalleryIndicator.propTypes = {
  inView: PropTypes.bool
};

export default GalleryIndicator;
