import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import commonConfig from "../../../../../config/common";
import { analyticsScrollImage } from "../../../../../state/modules/analytics/actions/__variants__/plp-mweb-image-gallery/v2";
import "../../../../../analytics/__variants__/plp-mweb-image-gallery/v2/emitterEvents";

const useImageInView = ({ galleryRef, loadOnTouchImages, image }) => {
  let observer;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [hasIntersectedOnce, setHasIntersectedOnce] = useState(false);
  const [nodes] = useState([]);
  const dispatch = useDispatch();
  const [, productId] = image.match(/\/(\d+)-/);
  const { imageGallerySwipeAnalytics } = commonConfig;

  useEffect(() => {
    if (!loadOnTouchImages) {
      return;
    }
    if (galleryRef.current) {
      observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const src = entry.target.src
                ? entry.target.src
                : entry.target.firstChild?.src;

              if (src) {
                setCurrentImageIndex(nodes.indexOf(src));
              }
            }
          });
        },

        {
          threshold: 0.98
        }
      );
      if (galleryRef.current.children) {
        const galleryArray = Array.from(galleryRef.current.children);

        galleryArray.forEach(child => {
          const src = child.src ? child.src : child.firstChild?.src;

          if (src) {
            if (nodes.indexOf(src) === -1) {
              nodes.push(src);
              observer.observe(child);
            }
          }
        });
      }
    }

    return () => observer?.disconnect();
  }, [loadOnTouchImages]);

  useEffect(() => {
    if (
      imageGallerySwipeAnalytics &&
      currentImageIndex === 1 &&
      !hasIntersectedOnce
    ) {
      dispatch(analyticsScrollImage({ productId }));
      setHasIntersectedOnce(true);
    }
  }, [currentImageIndex]);

  return [currentImageIndex];
};

export default useImageInView;
