import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

const TrapFocus = ({ trapFocus, children, className }) => {
  const [trapped, setTrapped] = useState(trapFocus);
  const containerRef = useRef();

  useEffect(() => {
    if (trapped === trapFocus) return;

    if (trapFocus) {
      document.addEventListener("focusin", onFocus);
    } else {
      document.removeEventListener("focusin", onFocus);
    }
    setTrapped(trapFocus);

    return () => document.removeEventListener("focusin", onFocus);
  }, [trapFocus]);

  const onFocus = event => {
    if (!containerRef.current.contains(event.target)) {
      event.stopPropagation();
      containerRef.current.focus();
    }
  };

  return (
    <div className={className} tabIndex="-1" ref={containerRef}>
      {children}
    </div>
  );
};

TrapFocus.defaultProps = {
  className: ""
};

TrapFocus.propTypes = {
  trapFocus: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default TrapFocus;
