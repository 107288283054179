import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getFacets,
  selectAll,
  clear,
  changeMultiFacetAndApplyIfLargeMedium
} from "../../state/modules/search";
import { getFloor } from "../../state/modules/config/selectors";
import { FLOOR_DEFAULT } from "../../state/modules/config/constants";
import { getLocation } from "../../state/modules/router/selectors";
import {
  analyticsOpenRefinementType,
  analyticsCloseRefinementType
} from "../../state/modules/analytics/actions";
import HorizontalRefinements from "../../components/HorizontalRefinements";
import { getSwatches } from "../../state/modules/facet/selectors";
import { getColourEnrichments } from "../../state/modules/facet/actions";
import { COLOUR_FACET_ID } from "../../state/modules/facet/constants";
import commonConfig from "../../config/common";

const { newFacets } = commonConfig;

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        changeMultiFacet: changeMultiFacetAndApplyIfLargeMedium,
        selectAll,
        clear,
        analyticsOpenRefinementType,
        analyticsCloseRefinementType
      },
      dispatch
    ),
    getColourEnrichments: () => dispatch(getColourEnrichments())
  };
}

function mapStateToProps(state) {
  return {
    facets: getFacets(state),
    query: getLocation(state).query,
    hasFloor: getFloor(state) !== FLOOR_DEFAULT,
    swatchesMap: getSwatches(state),
    colourFilterPresent: getFacets(state).some(
      ({ id }) => id === COLOUR_FACET_ID
    ),
    newFacets
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HorizontalRefinements);
