import { ERROR_TYPE } from "../../../state/modules/error";

const getTranslationKeys = type => {
  switch (type) {
    case ERROR_TYPE.network:
      return {
        header: "plp_web_errors_no_connection_header",
        details: "plp_web_errors_no_connection_details"
      };
    default:
      return {
        header: "plp_web_errors_oops",
        details: "plp_web_add_to_saved_generic_failure"
      };
  }
};

export default getTranslationKeys;
