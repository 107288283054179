import ErrorAlert from "../../components/ErrorAlert";
import { useDispatch, useSelector } from "react-redux";

import { undoSearch, retrySearch } from "../../state/modules/search/actions";
import { clearError, getError } from "../../state/modules/error";
import { getIsLoading } from "../../state/modules/search/selectors";

const ConnectedErrorAlert = props => {
  const dispatch = useDispatch();

  const error = useSelector(getError);
  const isLoading = useSelector(getIsLoading);

  return (
    <ErrorAlert
      error={error}
      isLoading={isLoading}
      undoSearch={() => dispatch(undoSearch())}
      retrySearch={args => dispatch(retrySearch(args))}
      clearError={() => dispatch(clearError())}
      {...props}
    />
  );
};

export default ConnectedErrorAlert;
