import styles from "./index.css";
import { withTranslation } from "../translation";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import getRankingDisclaimerLink from "./getRankingDisclaimerLink";
import { getStoreCode } from "../../state/modules/config/selectors";

const RankingDisclaimer = ({ formatTranslation }) => {
  const storeCode = useSelector(getStoreCode);
  const rankingDisclaimerLink = getRankingDisclaimerLink(storeCode);

  if (!rankingDisclaimerLink) return null;

  return (
    <a
      className={styles.RankingDisclaimer}
      href={rankingDisclaimerLink}
      target="_blank"
      rel="noreferrer"
    >
      {formatTranslation("how_we_rank_content")}
      <span className={styles.productNewPageSmall} />
    </a>
  );
};

RankingDisclaimer.propTypes = {
  formatTranslation: PropTypes.func.isRequired
};

export default withTranslation(RankingDisclaimer);
