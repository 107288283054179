import NewPill from "./NewPill";
import PropTypes from "prop-types";
import { useState } from "react";
import { withTranslation } from "../../../../../translation";
import styles from "./index.css";
import CloseButton from "./CloseButton";

const PromoLabelOnboardingMessage = ({
  formatTranslation,
  promoOnboardDismissed,
  updatePromoOnboardDismissed
}) => {
  const [isDismissed, setIsDismissed] = useState(promoOnboardDismissed);

  if (isDismissed) {
    return null;
  }

  return (
    <div className={styles.promoLabelOnboardingMessage}>
      <div className={styles.headerIconContainer}>
        <NewPill />
        <CloseButton
          onClick={() => {
            setIsDismissed(true);
            updatePromoOnboardDismissed(true);
          }}
        />
      </div>
      <div className={styles.informationWrapper}>
        <div className={styles.messageWrapper}>
          <p className={styles.title}>
            {formatTranslation("plp_onboarding_promo_label_title")}
          </p>
          <p
            className={styles.text}
            aria-label={formatTranslation(
              "plp_onboarding_promo_label_subtitle_accessibility"
            )}
          >
            {formatTranslation(
              "plp_onboarding_promo_label_subtitle_before_icon"
            )}
            <span className={styles.iconTag} aria-hidden={true}></span>
            {formatTranslation(
              "plp_onboarding_promo_label_subtitle_after_icon"
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

PromoLabelOnboardingMessage.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  promoOnboardDismissed: PropTypes.bool,
  updatePromoOnboardDismissed: PropTypes.func.isRequired
};

export default withTranslation(PromoLabelOnboardingMessage);
