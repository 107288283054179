import PriceSlider from "../../../PriceSlider";
import styles from "./PriceRange.css";

const PriceRange = props => (
  <div className={styles.container}>
    <PriceSlider {...props} />
  </div>
);

export default PriceRange;
