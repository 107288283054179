import PropTypes from "prop-types";
import classnames from "classnames/bind";
import styles from "./Results.css";
import ResultsProgressOverlay from "./ResultsProgressOverlay";
import renderProducts from "./renderProducts";

const combineClasses = classnames.bind(styles);

const ResultsContent = ({ isLoading, pages, ...rest }) => {
  const hasPages = pages.length > 0;

  return (
    <div
      className={combineClasses({
        results: true,
        noResults: !hasPages
      })}
    >
      {isLoading && <ResultsProgressOverlay />}
      {hasPages &&
        renderProducts({
          pages,
          ...rest
        })}
    </div>
  );
};

ResultsContent.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  offset: PropTypes.object.isRequired,
  tileToFocus: PropTypes.shape({
    first: PropTypes.bool,
    last: PropTypes.bool,
    pageNumber: PropTypes.number
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  advert: PropTypes.func,
  upSellCarousel: PropTypes.func
};

export default ResultsContent;
