import LoadMoreButton from "./LoadMoreButton";
import PropTypes from "prop-types";
import { withTranslation } from "../translation";
import { useDispatch, useSelector } from "react-redux";
import { loadMore } from "../../state/modules/search/actions";
import { getNextPagePath } from "../../state/modules/search/getNextPagePath";
import { getIsLoadingMore } from "../../state/modules/search/getIsLoadingMore";

const ConnectedLoadMoreButton = ({ formatTranslation, formatLink }) => {
  const dispatch = useDispatch();

  return (
    <LoadMoreButton
      isLoadingMore={useSelector(getIsLoadingMore)}
      loadMore={args => dispatch(loadMore(args))}
      nextPagePath={useSelector(getNextPagePath)}
      formatTranslation={formatTranslation}
      formatLink={formatLink}
    />
  );
};

ConnectedLoadMoreButton.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  formatLink: PropTypes.func.isRequired
};

export default withTranslation(ConnectedLoadMoreButton);
