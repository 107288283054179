import { useSelector, useDispatch } from "react-redux";
import {
  getPages,
  getOffset,
  getIsNewPage,
  getIsLoading,
  getIsLoadingPrevious,
  getShowLoadMore,
  getShowLoadPrevious,
  getTileToFocus
} from "../../../../../state/modules/search/selectors";
import { updateImageGalleryOnboardDismissed } from "../../../../../state/modules/search/__variants__/plp-mweb-image-gallery/actions";
import { getImageGalleryOnboardDismissed } from "../../../../../state/modules/search/__variants__/plp-mweb-image-gallery/selectors";
import { isErrorOfTypeServer as getIsErrorOfTypeServer } from "../../../../../state/modules/error";
import { Results } from "../../../Results";
import { getPlatform } from "../../../../../state/modules/config/selectors";
import { getCategoryTreatments } from "../../../../../state/modules/category";

const ConnectedResults = props => {
  const dispatch = useDispatch();

  const pages = useSelector(getPages);
  const offset = useSelector(getOffset);
  const isErrorOfTypeServer = useSelector(getIsErrorOfTypeServer);
  const isNewPage = useSelector(getIsNewPage);
  const isLoading = useSelector(getIsLoading);
  const isLoadingPrevious = useSelector(getIsLoadingPrevious);
  const showLoadMore = useSelector(getShowLoadMore);
  const showLoadPrevious = useSelector(getShowLoadPrevious);
  const tileToFocus = useSelector(getTileToFocus);
  const imageGalleryOnboardDismissed = useSelector(
    getImageGalleryOnboardDismissed
  );
  const platform = useSelector(getPlatform);
  const categoryTreatments = useSelector(getCategoryTreatments);

  return (
    <Results
      {...{
        ...props,
        pages,
        isErrorOfTypeServer,
        isLoading,
        isNewPage,
        isLoadingPrevious,
        showLoadMore,
        showLoadPrevious,
        tileToFocus,
        offset,
        imageGalleryOnboardDismissed,
        platform,
        categoryTreatments,
        updateImageGalleryOnboardDismissed: args =>
          dispatch(updateImageGalleryOnboardDismissed(args))
      }}
    />
  );
};

export default ConnectedResults;
