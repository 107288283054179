import PropTypes from "prop-types";
import styles from "./styles.css";
import shopMen from "./shop-men.jpg";
import shopWomen from "./shop-women.jpg";
import ImageLink from "./ImageLink";

const ShopWomenAndMen = ({
  shopWomenLink,
  shopWomenMessage,
  shopMenLink,
  shopMenMessage
}) => (
  <ul className={styles.links}>
    <li className={styles.linksItem}>
      <ImageLink url={shopWomenLink} image={shopWomen}>
        {shopWomenMessage}
      </ImageLink>
    </li>
    <li className={styles.linksItem}>
      <ImageLink url={shopMenLink} image={shopMen}>
        {shopMenMessage}
      </ImageLink>
    </li>
  </ul>
);

ShopWomenAndMen.propTypes = {
  shopWomenLink: PropTypes.string,
  shopWomenMessage: PropTypes.string,
  shopMenLink: PropTypes.string,
  shopMenMessage: PropTypes.string
};

ShopWomenAndMen.displayName = "ShopWomenAndMen";

export default ShopWomenAndMen;
