const getExecute = ({
  intersectionObserver,
  onViewBeacon,
  sendPlacementOnViewBeacon
}) => () => {
  navigator.sendBeacon(onViewBeacon);
  sendPlacementOnViewBeacon();
  intersectionObserver.disconnect();
};

export default getExecute;
