import withUpSellCarousel from "../../../../UpSellCarousel/withUpSellCarousel";
import { useSelector } from "react-redux";
import ProductListingPage from "../../../../ProductListingPage/ProductListingPageWrapper";
import getCarouselCategory from "./getCarouselCategory";
import PropTypes from "prop-types";

const ProductListingPageWithUpSellCarousel = withUpSellCarousel(
  ProductListingPage
);

const ProductListingPageContainer = ({ upSellCarousel, ...rest }) => {
  let carouselProps = null;
  if (!upSellCarousel) {
    const matchedCategory = useSelector(getCarouselCategory);

    if (matchedCategory) {
      const { title, subtitle, carouselCatId } = matchedCategory;
      carouselProps = {
        title,
        subtitle,
        carouselCatId,
        ...rest
      };
    }
  }
  const getUpsellCarouselProps = upSellCarousel =>
    upSellCarousel ? upSellCarousel.props : null;

  return (
    <ProductListingPageWithUpSellCarousel
      carouselProps={carouselProps || getUpsellCarouselProps(upSellCarousel)}
      {...rest}
    />
  );
};

ProductListingPageContainer.propTypes = {
  upSellCarousel: PropTypes.string
};

export default ProductListingPageContainer;
