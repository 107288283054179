import styles from "./index.css";
import config from "../../config/common";
import { Fragment } from "react";

const addAdvert = ({ advert, items, positionFinder }) => {
  const {
    adverts: { adPosition }
  } = config;

  if (!advert) {
    return [...items];
  }

  const positionInItemsArray = positionFinder({
    configuredPosition: adPosition,
    items
  });

  return [
    ...items.slice(0, positionInItemsArray),
    <Fragment key="advert">{advert({ className: styles.advert })}</Fragment>,
    ...items.slice(positionInItemsArray)
  ];
};

export default addAdvert;
