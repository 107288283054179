import HorizontalRefinements from "../HorizontalRefinements";
import styles from "./index.css";

const MediumLargeScreenHeader = () => (
  <div className={styles.container}>
    <HorizontalRefinements />
  </div>
);

export default MediumLargeScreenHeader;
