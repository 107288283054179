import {
  SEARCH_EXPERIMENTAL_PARAMS_KEY as searchExperimentalParamsFeatureName,
  SEARCH_GENDERED_SEARCH as searchGenderedSearchFeatureName
} from "../../lib/api/constants";
import { useContext, useEffect } from "react";
import { decisionsContext, audienceContext } from "../Features";
import { activateMvtFeature } from "@asosteam/asos-web-toggle-point-features";
import sdkInstanceProvider from "../../client/sdkProvider";

const useExperimentalParamsActivation = () => {
  const decisions = useContext(decisionsContext);
  const audience = useContext(audienceContext);
  const searchExperimentalParamsDecision =
    decisions?.[searchExperimentalParamsFeatureName];
  const searchGenderedSearchDecison =
    decisions?.[searchGenderedSearchFeatureName];

  useEffect(() => {
    if (searchExperimentalParamsDecision) {
      activateMvtFeature({
        sdkInstanceProvider,
        featureName: searchExperimentalParamsFeatureName,
        audience
      });
    }
  }, [searchExperimentalParamsDecision]);

  useEffect(() => {
    if (searchGenderedSearchDecison) {
      activateMvtFeature({
        sdkInstanceProvider,
        featureName: searchGenderedSearchFeatureName,
        audience
      });
    }
  }, [searchGenderedSearchDecison]);
};

export default useExperimentalParamsActivation;
