import PropTypes from "prop-types";
import StyleCount from "../../../../StyleCount";
import LoadPreviousButton from "../../../../LoadPrevious";
import RankingDisclaimer from "../../../../RankingDisclaimer";
import PromoLabelOnboardingMessage from "./PromoLabelOnboardingMessage";

const ResultsHeader = ({ showLoadPrevious, itemCount }) => (
  <>
    <PromoLabelOnboardingMessage />
    {showLoadPrevious ? (
      <LoadPreviousButton />
    ) : (
      <StyleCount itemCount={itemCount} />
    )}
    <RankingDisclaimer />
  </>
);

ResultsHeader.propTypes = {
  itemCount: PropTypes.number.isRequired,
  showLoadPrevious: PropTypes.bool.isRequired
};

export default ResultsHeader;
