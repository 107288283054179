const hashString = async message => {
  const notOnHttpLocally = !!crypto.subtle;
  if (notOnHttpLocally) {
    const messageBuffer = new TextEncoder().encode(message);
    const hashBuffer = await crypto.subtle.digest("SHA-256", messageBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map(b => b.toString(16).padStart(2, "0"))
      .join("");

    return hashHex;
  }
};

export default hashString;
