import ensurePageSetup from "./ensurePageSetup";
import { useEffect } from "react";
import setPageLevelTargeting from "./setPageLevelTargeting";
import enableServices from "./enableServices";

const useAdverts = ({
  scriptSrc,
  fetchMarginPercent,
  renderMarginPercent,
  mobileMarginScaling,
  targeting,
  hashedCustomerIdPromise
}) => {
  useEffect(() => {
    ensurePageSetup({
      scriptSrc,
      fetchMarginPercent,
      renderMarginPercent,
      mobileMarginScaling,
      hashedCustomerIdPromise
    });
    setPageLevelTargeting({ targeting });
    enableServices();
  }, []);
};

export default useAdverts;
