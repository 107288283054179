import PropTypes from "prop-types";
import { withTranslation } from "../translation";
import styles from "./index.css";

// Order of max and value attributes in the progress bar matters for IE browsers
// https://stackoverflow.com/questions/38841097/react-progress-element-has-a-bug-on-ie10
export const ProgressBar = ({
  value,
  max,
  formatTranslation,
  formatNumber
}) => (
  <div className={styles.container}>
    <p className={styles.label}>
      {formatTranslation("plp_web_search_progress", {
        formattedMax: formatNumber(max),
        formattedValue: formatNumber(value),
        smart_count: max
      })}
    </p>
    <progress
      max={max}
      value={value}
      className={styles.progressBar}
      aria-hidden="true"
    />
  </div>
);

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  formatNumber: PropTypes.func.isRequired
};

ProgressBar.displayName = "ProgressBar";

export default withTranslation(ProgressBar);
