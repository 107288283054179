import { useSelector } from "react-redux";
import ListingPage from "../../components/ListingPage";
import getAdditionalItemPositionFinder from "./getAdditionalItemPositionFinder";

const ConnectedListingPage = props => {
  const positionFinder = useSelector(getAdditionalItemPositionFinder);

  return (
    <ListingPage {...props} getAdditionalItemPositionFinder={positionFinder} />
  );
};

export default ConnectedListingPage;
