import cookie from "js-cookie";

import {
  SMALL_VIEWPORT,
  MEDIUM_VIEWPORT,
  LARGE_VIEWPORT,
  FOUR_COLUMNS,
  THREE_COLUMNS,
  TWO_COLUMNS
} from "./constants";

import { MOBILE, TABLET, DESKTOP } from "../config/constants";

export function viewportCategoryToColumnCount(viewportCategory) {
  switch (viewportCategory) {
    case SMALL_VIEWPORT:
      return TWO_COLUMNS;
    case MEDIUM_VIEWPORT:
      return THREE_COLUMNS;
    case LARGE_VIEWPORT:
    default:
      return FOUR_COLUMNS;
  }
}

export function columnCountToViewportCategory(imageSize) {
  switch (imageSize) {
    case TWO_COLUMNS:
      return SMALL_VIEWPORT;
    case THREE_COLUMNS:
      return MEDIUM_VIEWPORT;
    case FOUR_COLUMNS:
    default:
      return LARGE_VIEWPORT;
  }
}

export const COLUMNS_COUNT_COOKIE_KEY = "plp_columsCount";

export function getViewportCategoryFromReqCookieOrPlatform(
  { cookies },
  platform
) {
  const imageSize = cookies[COLUMNS_COUNT_COOKIE_KEY];

  return imageSize
    ? columnCountToViewportCategory(imageSize)
    : getDefaultViewportCategoryForPlatform(platform);
}

export function setViewportCategoryCookie(viewportCategory) {
  if (CLIENT) {
    cookie.set(
      COLUMNS_COUNT_COOKIE_KEY,
      viewportCategoryToColumnCount(viewportCategory),
      { expires: 365 }
    );
  }
}

const getDefaultViewportCategoryForPlatform = platform => {
  switch (platform) {
    case MOBILE:
      return SMALL_VIEWPORT;
    case TABLET:
      return MEDIUM_VIEWPORT;
    case DESKTOP:
    default:
      return LARGE_VIEWPORT;
  }
};
