import PropTypes from "prop-types";
import { useContext } from "react";
import classnames from "classnames";
import styles from "./index.css";
import TileContext from "../../TileContext";
import { TILE_SIZES } from "../../../helpers/constants";

const { TILE_SIZE_TWO_BY_ONE, TILE_SIZE_TWO_BY_TWO } = TILE_SIZES;

export const ProductDescription = ({ text, labelledById }) => {
  const { tileSize } = useContext(TileContext);

  return (
    <p
      id={labelledById}
      className={classnames(styles.productDescription, {
        [styles.twoByOne]: tileSize === TILE_SIZE_TWO_BY_ONE,
        [styles.twoByTwo]: tileSize === TILE_SIZE_TWO_BY_TWO
      })}
    >
      {text}
    </p>
  );
};

ProductDescription.propTypes = {
  text: PropTypes.string.isRequired,
  labelledById: PropTypes.string.isRequired
};

export default ProductDescription;
