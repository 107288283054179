import getSaveItemErrorKeys from "./saveItemErrorCopyProvider";
import getSearchErrorKeys from "./searchErrorCopyProvider";

import { ERROR_ORIGIN } from "../constants";

const getErrorKeys = (errorOrigin, errorType) => {
  switch (errorOrigin) {
    case ERROR_ORIGIN.saveItem:
      return getSaveItemErrorKeys(errorType);
    default:
      return getSearchErrorKeys(errorType);
  }
};

export default getErrorKeys;
