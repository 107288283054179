import { getBadgeText } from "./getBadgeText";
import { withTranslation } from "../../../translation";
import PropTypes from "prop-types";
import {
  MIX_MATCH,
  MORE_COLOURS,
  MORE_COLOURS_FACET
} from "../../../../state/modules/search/constants";

const ProductBadge = ({
  labels: { productBadge: labelledById },
  badgeType,
  formatTranslation,
  className
}) => {
  if (!badgeType) {
    return null;
  }

  return (
    <div id={labelledById} className={className}>
      {getBadgeText({ badgeType, formatTranslation })}
    </div>
  );
};

ProductBadge.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  badgeType: PropTypes.oneOf([MIX_MATCH, MORE_COLOURS, MORE_COLOURS_FACET]),
  labels: PropTypes.shape({
    productBadge: PropTypes.string.isRequired
  }).isRequired,
  className: PropTypes.string
};

export default withTranslation(ProductBadge);
