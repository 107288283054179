import {
  MORE_COLOURS,
  MORE_COLOURS_FACET
} from "../../../../state/modules/search/constants";

export const getBadgeText = ({ badgeType, formatTranslation }) => {
  if (badgeType === MORE_COLOURS || badgeType === MORE_COLOURS_FACET) {
    return formatTranslation("plp_web_product_tile_badge_more_colors");
  }

  return formatTranslation("plp_web_product_tile_badge_mix_match");
};
