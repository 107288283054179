import PropTypes from "prop-types";
import RefinementItem from "../RefinementItem";
import RefinementItemRange from "../RefinementItemRange";
import { isFacetPriceRange } from "../../../state/modules/search/priceRangeSelectors";
import NewPill from "../../../containers/NewPill";

const PrimaryListFacetItem = ({
  facet,
  styles,
  open,
  range,
  formatCurrency,
  isNew
}) => (
  <li className={styles.facet} data-auto-id={facet.facetType}>
    <button
      data-auto-id={facet.id}
      className={styles.button}
      onClick={() => open(facet)}
    >
      {isFacetPriceRange(facet) ? (
        <RefinementItemRange
          facet={facet}
          range={range}
          formatCurrency={value => formatCurrency(value, true)}
        />
      ) : (
        <RefinementItem facet={facet} />
      )}
    </button>
    {isNew && <NewPill className={styles.newPill} />}
  </li>
);

PrimaryListFacetItem.propTypes = {
  formatCurrency: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired,
  range: PropTypes.object.isRequired,
  facet: PropTypes.object.isRequired,
  open: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired
};

export default PrimaryListFacetItem;
