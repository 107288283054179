import PropTypes from "prop-types";
import FacetValues from "../FacetValues";
import classnames from "classnames";
import ClearButton from "../../ToggleButton/ClearButton";
import SelectAllButton from "../../ToggleButton/SelectAllButton";
import { getFacetValuesToDisplay } from "../../../helpers/getFacetValuesToDisplay";
import withTextFilter from "../../../containers/withTextFilter";
import { withTranslation } from "../../translation";
import { NoFilterSearchBoxMatches } from "../../NoFilterSearchBoxMatches";

const SecondaryList = props => {
  const {
    styles,
    facet,
    formatTranslation,
    isFiltered,
    searchBox,
    filteredFacetValues,
    clearSearchBox
  } = props;

  if (facet === undefined) return null;

  const facetValuesToDisplay = getFacetValuesToDisplay({
    isFiltered,
    filteredFacetValues,
    facet
  });

  const facetToDisplay = {
    ...facet,
    facetValues: facetValuesToDisplay
  };

  const handleBackClick = () => {
    clearSearchBox && clearSearchBox();
    props.back(props.facet);
  };

  const handleViewItemsClick = () => {
    props.viewList(props.facet);
  };

  return (
    <div className={styles.secondaryList}>
      <header className={styles.refinementsHeader}>
        <div className={styles.topLine}>
          <h3 className={classnames(styles.refinementsTitle)}>
            <button className={styles.backBtn} onClick={handleBackClick}>
              {facet.name}
            </button>
          </h3>
          {!isFiltered && (
            <>
              <ClearButton
                className={styles.clearBtn}
                facets={[props.facet]}
                onClick={() => props.clear(facet.id)}
              >
                {formatTranslation("plp_web_refinements_list_clear")}
              </ClearButton>
              <SelectAllButton
                className={styles.selectAllBtn}
                openFacet={props.facet}
                onClick={() => props.selectAll(facet.id)}
              >
                {formatTranslation("plp_web_refinements_list_all")}
              </SelectAllButton>
            </>
          )}
        </div>
        {searchBox}
      </header>

      <div
        className={classnames(styles.refinementsListWrapper, {
          [styles.filterableRefinementsListWrapper]: searchBox,
          [styles.unfilterableRefinementsListWrapper]: !searchBox
        })}
      >
        <div
          className={classnames(styles.refinementsList, {
            [styles.noPadding]: facet.id === "brand"
          })}
        >
          <FacetValues
            facet={facetToDisplay}
            changeMultiFacet={props.changeMultiFacet}
          />
          <NoFilterSearchBoxMatches
            isFiltered={isFiltered}
            filteredFacetValues={filteredFacetValues}
            formatTranslation={formatTranslation}
          />
        </div>
      </div>
      <footer className={styles.footer}>
        <button className={styles.viewItemsBtn} onClick={handleViewItemsClick}>
          {formatTranslation("plp_web_refinements_list_view_items", {
            smart_count: props.itemCount
          })}
        </button>
      </footer>
    </div>
  );
};

SecondaryList.propTypes = {
  secondaryListIsOpen: PropTypes.bool.isRequired,
  facet: PropTypes.object,
  itemCount: PropTypes.number,
  styles: PropTypes.object.isRequired,
  clear: PropTypes.func.isRequired,
  selectAll: PropTypes.func.isRequired,
  viewList: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  changeMultiFacet: PropTypes.func.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  isFiltered: PropTypes.bool,
  searchBox: PropTypes.object,
  filteredFacetValues: PropTypes.array,
  clearSearchBox: PropTypes.func
};

export default withTextFilter(withTranslation(SecondaryList));
