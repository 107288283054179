import PropTypes from "prop-types";
import { withTranslation } from "../../../../../../translation";

const PromoBadge = ({
  isPromotion,
  formatTranslation,
  labels: { promoBadge: labelledById },
  className
}) => {
  if (!isPromotion) {
    return null;
  }

  return (
    <div
      id={labelledById}
      aria-hidden={true}
      aria-label={formatTranslation("plp_promo_label_accessibility")}
      className={className}
    >
      {formatTranslation("plp_promo_label")}
    </div>
  );
};

PromoBadge.propTypes = {
  isPromotion: PropTypes.bool.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  labels: PropTypes.shape({
    promoBadge: PropTypes.string.isRequired
  }).isRequired,
  className: PropTypes.string
};

export default withTranslation(PromoBadge);
