import PropTypes from "prop-types";
import styles from "./index.css";
import originalStyles from "../../../index.css";
import { forwardRef } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { getCategoryTreatments } from "../../../../../state/modules/category";
import { getViewportCategory } from "../../../../../state/modules/layout/selectors";
import { LARGE_VIEWPORT } from "../../../../../state/modules/layout/constants";

const ProductHeroContainer = forwardRef(
  ({ children, className }, ref = null) => {
    const treatments = useSelector(getCategoryTreatments);
    const viewportCategory = useSelector(getViewportCategory);
    const useOriginalStyles = treatments && viewportCategory !== LARGE_VIEWPORT;

    return (
      <div
        className={classnames(className, {
          [styles.productHeroContainer]: !useOriginalStyles,
          [originalStyles.productHeroContainer]: useOriginalStyles
        })}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

ProductHeroContainer.displayName = "ProductHeroContainer";

ProductHeroContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  className: PropTypes.string
};

export default ProductHeroContainer;
