import {
  SEARCHBOX_RENDERING_THRESHOLD,
  UNFILTERABLE_FACET_IDS
} from "./constants";

const isFilterableFacet = ({ facet: { id, facetValues } }) =>
  facetValues.length > SEARCHBOX_RENDERING_THRESHOLD &&
  !UNFILTERABLE_FACET_IDS.includes(id);

export default isFilterableFacet;
