import styles from "./index.css";
import PropTypes from "prop-types";
import { withTranslation } from "../../../translation";
import classnames from "classnames";

const ProductSponsored = ({ formatTranslation, className, labelledById }) => (
  <div
    id={labelledById}
    className={classnames(styles.productSponsored, className)}
  >
    {formatTranslation("sponsored_product_ads")}
  </div>
);

ProductSponsored.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  className: PropTypes.string,
  labelledById: PropTypes.string.isRequired
};

export default withTranslation(ProductSponsored);
