import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import NoResults from "../NotFound/NoResults";
import AutoRetry from "./AutoRetry";
import ResultsView from "./ResultsView";

export const Results = ({
  pages,
  location,
  hasFacets,
  isErrorOfTypeServer = false,
  isLoadingPrevious = false,
  ...rest
}) => {
  const [previousBodyHeight, setPreviousBodyHeight] = useState();

  useEffect(() => {
    if (isLoadingPrevious) {
      setPreviousBodyHeight(document.body.scrollHeight);
    } else {
      const heightDifference = document.body.scrollHeight - previousBodyHeight;
      window.scrollTo(0, heightDifference);
    }
  }, [isLoadingPrevious]);

  const searchTerm = decodeURIComponent(
    new URLSearchParams(location.search).get("q")
  );

  if (isErrorOfTypeServer) {
    return <AutoRetry />;
  }

  if (!pages.length && !hasFacets) {
    return <NoResults searchTerm={searchTerm} />;
  }

  return <ResultsView pages={pages} {...rest} />;
};

Results.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  isLoadingPrevious: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }),
  hasFacets: PropTypes.bool.isRequired,
  isErrorOfTypeServer: PropTypes.bool.isRequired
};

export default Results;
