import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "../translation/";
import {
  getFacets,
  getStateForPriceSlider,
  done,
  changeMultiFacetAndApplyIfLargeMedium,
  selectAll,
  clearAll,
  clear,
  clearSelections
} from "../../state/modules/search";
import { COLOUR_FACET_ID } from "../../state/modules/facet/constants";
import { getSwatches } from "../../state/modules/facet/selectors";
import { analyticsOpenRefinementType } from "../../state/modules/analytics/actions";
import { getFilterOverlayVisible } from "../../state/modules/layout/selectors";
import { hideFilterOverlay } from "../../state/modules/layout/actions";
import { getColourEnrichments } from "../../state/modules/facet/actions";
import noTouchAction from "../../helpers/noTouchAction";
import styles from "./RefinementList.css";
import PrimaryList from "./PrimaryList";
import SecondaryList from "./SecondaryList";
import classNames from "classnames/bind";
import { getFacetWithSwatches } from "../../client/getFacetWithSwatches";
import {
  MOBILE_DONE,
  MOBILE_VIEW_ITEMS
} from "../../state/modules/search/constants";
import commonConfig from "../../config/common";

const styleContext = classNames.bind(styles);
const { newFacets } = commonConfig;

export const RefinementList = ({
  isVisible,
  facets,
  getColourEnrichments: fetchColourEnrichments,
  clearSelections: clearCurrentSelections,
  analyticsOpenRefinementType,
  done: completed,
  hideFilterOverlay: hideFilters,
  range,
  clearAll: clearAllSelections,
  formatTranslation,
  formatCurrency,
  changeMultiFacet,
  itemCount,
  swatchesMap,
  clear: clearList,
  selectAll: selectAllList,
  colourFilterPresent
}) => {
  const [secondaryListIsOpen, setSecondaryListIsOpen] = useState(false);
  const [openItemId, setOpenItemId] = useState(null);

  useEffect(() => {
    if (isVisible) {
      setSecondaryListIsOpen(false);
      clearCurrentSelections();
      noTouchAction(isVisible);
    }
  }, [isVisible]);

  useEffect(() => {
    if (colourFilterPresent) {
      fetchColourEnrichments();
    }
  }, [colourFilterPresent]);

  const getOpenItem = () => {
    const facet = facets.find(({ id }) => id === openItemId);

    if (!facet || facet.id !== COLOUR_FACET_ID) {
      return facet;
    }

    return getFacetWithSwatches({ facet, swatchesMap });
  };

  const setItemCount = (facetId = false) => {
    if (facetId) {
      const selectedFacet = facets.filter(
        f => f.id === facetId && f.hasSelectedValues
      );

      if (selectedFacet.length) {
        return selectedFacet[0].facetValues
          .map(fv => {
            if (fv.isSelected === true) {
              return fv.count;
            }

            return 0;
          })
          .reduce((accumulator, count) => accumulator + count);
      }
    }

    return itemCount;
  };

  const open = ({ id, name }) => {
    setOpenItemId(id);
    setSecondaryListIsOpen(true);
    analyticsOpenRefinementType({ id, name });
  };

  const back = facet => {
    completed(facet, MOBILE_DONE);
    setSecondaryListIsOpen(false);
  };

  const handleViewListClick = facet => {
    hideFilters();
    completed(facet, MOBILE_VIEW_ITEMS);
    setSecondaryListIsOpen(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.refinementList}>
      <div
        className={styleContext({
          navigation: true,
          navigation__secondary: secondaryListIsOpen
        })}
      >
        <PrimaryList
          facets={facets}
          newFacets={newFacets}
          range={range}
          clearAll={clearAllSelections}
          formatTranslation={formatTranslation}
          formatCurrency={formatCurrency}
          itemCount={itemCount}
          open={open}
          viewList={handleViewListClick}
          styles={styles}
        />
        <SecondaryList
          facet={getOpenItem()}
          itemCount={getOpenItem() ? setItemCount(openItemId) : 0}
          styles={styles}
          clear={clearList}
          selectAll={selectAllList}
          changeMultiFacet={changeMultiFacet}
          viewList={handleViewListClick}
          back={back}
          secondaryListIsOpen={secondaryListIsOpen}
        />
      </div>
    </div>
  );
};

RefinementList.propTypes = {
  facets: PropTypes.arrayOf(PropTypes.object).isRequired,
  newFacets: PropTypes.array.isRequired,
  range: PropTypes.object.isRequired,
  clearAll: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  selectAll: PropTypes.func.isRequired,
  hideFilterOverlay: PropTypes.func.isRequired,
  changeMultiFacet: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  clearSelections: PropTypes.func.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  formatCurrency: PropTypes.func.isRequired,
  itemCount: PropTypes.number.isRequired,
  isVisible: PropTypes.bool.isRequired,
  analyticsOpenRefinementType: PropTypes.func.isRequired,
  swatchesMap: PropTypes.object.isRequired,
  getColourEnrichments: PropTypes.func.isRequired,
  colourFilterPresent: PropTypes.bool.isRequired
};

RefinementList.displayName = "RefinementList";

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        hideFilterOverlay,
        changeMultiFacet: changeMultiFacetAndApplyIfLargeMedium,
        done,
        selectAll,
        clearAll,
        clear,
        clearSelections,
        analyticsOpenRefinementType
      },
      dispatch
    ),
    getColourEnrichments: () => dispatch(getColourEnrichments())
  };
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    range: getStateForPriceSlider(state),
    facets: getFacets(state),
    itemCount: state.search.itemCount,
    isVisible: getFilterOverlayVisible(state),
    swatchesMap: getSwatches(state),
    colourFilterPresent: getFacets(state).some(
      ({ id }) => id === COLOUR_FACET_ID
    )
  };
}

const RefinementListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(RefinementList));

export default RefinementListContainer;
