import { parse, format } from "url";

const QUERYSTRING_WHITE_LIST = [
  "cid",
  "currentpricerange",
  "page",
  "q",
  "refine",
  "sort"
];

export default function filterUrlForSeo(url) {
  const rawUrl = parse(url, true);

  const filteredQuery = Object.keys(rawUrl.query)
    .filter(key => QUERYSTRING_WHITE_LIST.includes(key))
    .reduce((acc, key) => {
      const value = rawUrl.query[key];

      if (key === "page" && value === "1") return acc;

      return {
        ...acc,
        [key]: value
      };
    }, {});

  return format({
    ...rawUrl,
    search: undefined,
    query: filteredQuery
  });
}
