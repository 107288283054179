import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getFilteredFacetValues } from "./getFilteredFacetValues";
import SearchBox from "./SearchBox";
import isFilterableFacet from "./isFilterableFacet";
import getOnChangeHandler from "./getOnChangeHandler";
import getOnClearHandler from "./getOnClearHandler";

function withTextFilter(WrappedComponent) {
  function WithTextFilter({ facet, onChange, ...rest }) {
    const [searchTerm, setSearchTerm] = useState("");

    const isFiltered = () => !!searchTerm.length;

    useEffect(() => () => onChange({ searchTerm: "" }), []);

    if (facet === undefined) return null;

    const onChangeHandler = getOnChangeHandler({ onChange, setSearchTerm });
    const onClearHandler = getOnClearHandler({ onChange, setSearchTerm });

    const searchBox = isFilterableFacet({ facet }) ? (
      <SearchBox
        searchTerm={searchTerm}
        onChange={onChangeHandler}
        onClear={onClearHandler}
      ></SearchBox>
    ) : null;

    return (
      <WrappedComponent
        facet={facet}
        searchBox={searchBox}
        isFiltered={isFiltered()}
        clearSearchBox={() => setSearchTerm("")}
        filteredFacetValues={getFilteredFacetValues({
          facetValues: facet.facetValues,
          searchTerm
        })}
        {...rest}
      />
    );
  }

  WithTextFilter.propTypes = {
    facet: PropTypes.shape({
      facetValues: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired
        })
      ).isRequired
    }),
    onChange: PropTypes.func
  };

  WithTextFilter.defaultProps = {
    onChange: () => {}
  };

  return WithTextFilter;
}

export default withTextFilter;
