import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { withTranslation } from "../translation/";
import { ERROR_TYPE } from "../../state/modules/error";
import { ERROR_ORIGIN } from "./constants";
import TouchOutside from "../TouchOutside";
import Escapable from "../Escapable";
import styles from "./ErrorAlert.css";
import getErrorKeys from "./CopyProvider/index";

const styleContext = classNames.bind(styles);

const getContainerClasses = type =>
  type === ERROR_TYPE.network
    ? {
        networkError: true
      }
    : {};

export const ErrorAlert = ({
  error,
  isLoading,
  undoSearch,
  clearError,
  retrySearch,
  formatTranslation
}) => {
  const [currentError, setCurrentError] = useState(error ? error : null);
  const { type: errorType, errorOrigin, actionType } = currentError || {};
  const buttonElement = useRef();

  useEffect(() => {
    if (error) {
      setCurrentError(error);
    }
  }, [error]);

  const isServerError = () => errorType === ERROR_TYPE.server;

  const getButtonElement = () => buttonElement.current;

  const isVisible = () => !!error && (!isServerError() || !isLoading);

  const getErrorButtonContent = () => {
    const { header, details } = getErrorKeys(errorOrigin, errorType);

    if (!error) {
      return (
        <>
          <div className={styles.content}>
            <div className={styles.message}>
              <div className={styles.header}>{formatTranslation(header)}</div>
              <div className={styles.details}>{formatTranslation(details)}</div>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <Escapable escapeHandler={outsideEscapeHandler[error.errorOrigin]} />
        <TouchOutside
          getElement={getButtonElement}
          outsideHandler={outsideEscapeHandler[error.errorOrigin]}
        />
        <div className={styles.content}>
          <div className={styles.message}>
            <div className={styles.header}>{formatTranslation(header)}</div>
            <div className={styles.details}>{formatTranslation(details)}</div>
          </div>
          {errorOrigin === ERROR_ORIGIN.search ? (
            <div className={styles.retryButton}>
              {formatTranslation("plp_web_errors_retry")}
            </div>
          ) : (
            <div className={styles.close} />
          )}
        </div>
      </>
    );
  };

  const outsideEscapeHandler = {
    [ERROR_ORIGIN.search]: () => {
      if (isServerError()) {
        clickHandler[ERROR_ORIGIN.search]();

        return;
      }

      undoSearch();
    },
    [ERROR_ORIGIN.saveItem]: () => {
      clearError();
    }
  };

  const clickHandler = {
    [ERROR_ORIGIN.search]: () => {
      retrySearch({ actionType, forceReapplyFacets: true });
    },
    [ERROR_ORIGIN.saveItem]: () => {
      clearError();
    }
  };

  const containerClasses = getContainerClasses(errorType);

  return (
    <button
      className={styleContext({
        errorAlert: true,
        visible: isVisible(),
        ...containerClasses
      })}
      ref={buttonElement}
      onClick={clickHandler[errorOrigin]}
    >
      {getErrorButtonContent()}
    </button>
  );
};

ErrorAlert.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired,
    errorOrigin: PropTypes.string.isRequired,
    actionType: PropTypes.string.isRequired
  }),
  formatTranslation: PropTypes.func.isRequired,
  undoSearch: PropTypes.func.isRequired,
  retrySearch: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

ErrorAlert.displayName = "ErrorAlert";

export default withTranslation(ErrorAlert);
