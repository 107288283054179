import ConnectedListingPage from "../../containers/ListingPage";
import config from "../../config/common";

const {
  api: {
    product: {
      search: { pageSize }
    }
  }
} = config;

const renderProducts = ({
  offset: { initial },
  pages,
  tileToFocus: {
    first: focusFirstTile,
    last: focusLastTile,
    pageNumber: focusPageNumber
  },
  advert,
  upSellCarousel,
  ...rest
}) => {
  const focusPageIndex = focusPageNumber - pages[0].pageNumber;

  return pages.map((page, index) => (
    <ConnectedListingPage
      focusFirstTile={index === focusPageIndex && focusFirstTile}
      focusLastTile={index === focusPageIndex && focusLastTile}
      isInitialPage={(page.pageNumber - 1) * pageSize === initial}
      page={page}
      key={page.pageNumber}
      advert={page.pageNumber === 1 ? advert : null}
      upSellCarousel={page.pageNumber === 1 ? upSellCarousel : null}
      {...rest}
    />
  ));
};

export default renderProducts;
