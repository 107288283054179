import PropTypes from "prop-types";
import StyleCount from "../../../../StyleCount";
import LoadPreviousButton from "../../../../LoadPrevious";
import RankingDisclaimer from "../../../../RankingDisclaimer";
import ImageGalleryOnBoardingMessage from "./ImageGalleryOnBoardingMessage";

const ResultsHeader = ({
  showLoadPrevious,
  itemCount,
  imageGalleryOnboardDismissed,
  updateImageGalleryOnboardDismissed,
  categoryTreatments,
  platform
}) =>
  showLoadPrevious ? (
    <>
      <LoadPreviousButton />
      <RankingDisclaimer />
    </>
  ) : (
    <>
      <StyleCount itemCount={itemCount} />
      <RankingDisclaimer />
      <ImageGalleryOnBoardingMessage
        updateImageGalleryOnboardDismissed={updateImageGalleryOnboardDismissed}
        imageGalleryOnboardDismissed={imageGalleryOnboardDismissed}
        categoryTreatments={categoryTreatments}
        platform={platform}
      />
    </>
  );
ResultsHeader.propTypes = {
  itemCount: PropTypes.number.isRequired,
  showLoadPrevious: PropTypes.bool.isRequired,
  imageGalleryOnboardDismissed: PropTypes.bool.isRequired,
  updateImageGalleryOnboardDismissed: PropTypes.func,
  platform: PropTypes.string.isRequired,
  categoryTreatments: PropTypes.object
};

export default ResultsHeader;
