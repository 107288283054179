import PropTypes from "prop-types";
import styles from "./index.css";
import ResultsHeader from "../ResultsHeader";
import ResultsFooter from "../ResultsFooter";
import withAdverts from "../withAdverts";
import ResultsContent from "../ResultsContent";

const ResultsView = ({
  pages,
  offset,
  tileToFocus,
  itemCount,
  showLoadPrevious,
  showLoadMore,
  isLoading,
  listAdvert,
  footerAdvert,
  upSellCarousel,
  ...rest
}) => (
  <div className={styles.resultsView}>
    <div className={styles.content}>
      <ResultsHeader
        showLoadPrevious={showLoadPrevious}
        itemCount={itemCount}
      />
      <ResultsContent
        isLoading={isLoading}
        pages={pages}
        tileToFocus={tileToFocus}
        offset={offset}
        advert={listAdvert}
        upSellCarousel={upSellCarousel}
        {...rest}
      />
      <ResultsFooter
        hasPages={!!pages.length}
        itemCount={itemCount}
        offset={offset}
        showLoadMore={showLoadMore}
      />
      {footerAdvert ? footerAdvert({ className: styles.footerAdvert }) : null}
    </div>
  </div>
);

ResultsView.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  offset: PropTypes.object.isRequired,
  tileToFocus: PropTypes.shape({
    first: PropTypes.bool,
    last: PropTypes.bool,
    pageNumber: PropTypes.number
  }).isRequired,
  itemCount: PropTypes.number.isRequired,
  showLoadPrevious: PropTypes.bool.isRequired,
  showLoadMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  listAdvert: PropTypes.func,
  footerAdvert: PropTypes.func,
  upSellCarousel: PropTypes.func
};

export default require("../../../containers/withTogglePoint").default(withAdverts(ResultsView), require.context("./__variants__/", true, /^\.\/([^/]+\/){2}index\.js$/))
