import CategoryPreviewCarousel from "../../components/CategoryPreview/Carousel";
import CategoryPreviewGrid from "../../components/CategoryPreview/Grid";

const CATEGORY_PREVIEW_VIEW_TYPE_TO_COMPONENT_MAP = {
  grid: CategoryPreviewGrid,
  carousel: CategoryPreviewCarousel
};

const categoryPreviewFactory = viewType =>
  CATEGORY_PREVIEW_VIEW_TYPE_TO_COMPONENT_MAP[viewType];

export default categoryPreviewFactory;
