import { UPDATE_PROMO_ONBOARD_DISMISS } from "./constants";
import { setPromoOnboardDismissedFromCookie } from "./promoOnboardDismissed";

export const updatePromoOnboardDismissed = promoOnboardDismissed => {
  setPromoOnboardDismissedFromCookie(promoOnboardDismissed);

  return {
    type: UPDATE_PROMO_ONBOARD_DISMISS,
    payload: {
      promoOnboardDismissed
    }
  };
};
