import { useSelector } from "react-redux";
import { getSeoImportantFacets } from "../../state/modules/category/selectors";
import Pill from "../CategoryHeader/Pill";
import HorizontalScrollList from "../HorizontalScrollList";
import styles from "./index.css";

const CategoryRefinementLinks = () => {
  const seoImportantFacets = useSelector(getSeoImportantFacets);

  if (!seoImportantFacets?.length) return null;

  return (
    <HorizontalScrollList className={styles.categoryRefinementLinks}>
      {seoImportantFacets.map(({ friendlyName, linkUrl }) => (
        <Pill
          key={friendlyName}
          friendlyName={friendlyName}
          linkUrl={linkUrl}
        />
      ))}
    </HorizontalScrollList>
  );
};

export default CategoryRefinementLinks;
